import React, { useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import OtpModal from "../components/OtpModal";
import TopNavigation from "../components/TopNavigation";
import { UserContext } from "../context/UserContext";
import { authenticateAPI, getOtpAPI } from "../service/AuthService";
import { toast } from "react-toastify";
import PhoneInput from "../components/PhoneInput";
import { LoadingContext } from "../context/LoadingContext";
import JSEncrypt from "jsencrypt";
import { PUBLIC_KEY } from "../utils/constants";

export default function Login(router) {
  const {
    history: {
      location: {
        state: { phoneNumber },
      },
    },
  } = router;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const { isLoggedIn, setUser, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [otpWaitingTime, setOtpWaitingTime] = useState(null);
  const history = useHistory();
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  const handleSubmit = async (isOtpLogin = false) => {
    setIsLoading(true);
    if (isOtpLogin) {
      const { data, statusCode, response, message } = await getOtpAPI(
        phoneNumber,
        "LOGIN"
      );
      if (statusCode == 200) {
        setIsLoading(false);
        setIsOtpModalOpen(true);
      } else if (statusCode != 200) {
        setOtpWaitingTime(parseInt(data?.time));
        setIsOtpModalOpen(true);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      if (password.length < 6 || password == "") return setPasswordError(false);
      await loginWithPassword(phoneNumber, encrypt.encrypt(password), false);
      setIsLoading(false);
    }
  };
  const loginWithPassword = async (
    phoneNumber,
    password,
    isOtpLogin = false,
    otp
  ) => {
    setIsLoading(true);
    try {
      var { data, statusCode, message } = await authenticateAPI({
        mobile: phoneNumber,
        password,
        passwordLogin: !isOtpLogin,
        otp,
      });
      if (statusCode == 200) {
        setUser({
          isLoggedIn: true,
          User: data.User,
          token: data.jwt,
        });
        setIsLoading(false);
        window.location.reload();
      } else if (statusCode != 200) {
        toast(message, { type: "error" });
        setIsLoading(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setIsLoading(false);
    }
  };
  return (
    <div className="component_body  justify-start">
      <TopNavigation title={"LOGIN"} isBackNav={true} isLoginNav={true} />
      <OtpModal
        phoneNumber={phoneNumber}
        isOpen={isOtpModalOpen}
        onClose={setIsOtpModalOpen}
        isLogin={loginWithPassword}
        payload={user}
        otpWaitingTime={otpWaitingTime}
      />
      <img
        className="w-[30%]  sm:w-[25%]"
        src={require("../assets/img/full_icon.png")}
      />
      <div className="w-[95%] mt-10 sm:w-[80%]  text-[#7C7C7C]  px-5 py-5 sm:p-8 rounded-[19px] flex flex-col items-start">
        <label className="text-sm mb-2">Phone Number *</label>
        <PhoneInput
          selectedCountry={selectedCountry}
          handleSelectedCountry={setSelectedCountry}
          phoneNumber={phoneNumber}
          disabled={true}
          shadow={false}
          bg={"#1D2023"}
        />
        <label className="text-sm mt-1 mb-2">Password *</label>
        <div className="styled_input custom_input">
          <input
            className="border-none outline-none"
            onChange={(e) => setPassword(e.target.value)}
            type={isPasswordVisible ? "text" : "password"}
          />
          {isPasswordVisible ? (
            <svg
              onClick={() => setIsPasswordVisible(false)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          ) : (
            <svg
              onClick={() => setIsPasswordVisible(true)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              />
            </svg>
          )}
        </div>
        <div className="text-[#9d2e3d] h-[20px] text-sm mt-1">
          {!passwordError ? "Password is required" : ""}
        </div>
        <div
          onClick={() =>
            history.push("/forgot-password", {
              mobileNumber: phoneNumber,
              toChangeMainAccountPassword: true,
            })
          }
          className="w-[100%] flex justify-end mt-1"
        >
          <span className="text-sm cursor-pointer text-amber-500">Forgot Password</span>
        </div>
      </div>

      <button
        onClick={() => handleSubmit()}
        className="w-[40%] p-3 text-black font-bold  mt-5 yellow_button flex justify-center items-center"
      >
        Login
      </button>
      <span className="mt-5 text-white">Or</span>
      <span className="th_login mb-5">
        Login With <span style={{color:"#FFBF44", cursor:"pointer"}}  onClick={() => handleSubmit(true)}>OTP</span>
      </span>
    </div>
  );
}
