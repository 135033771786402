import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingContext } from "../context/LoadingContext";
import { manageCoinsAPI } from "../service/UserService";
import CustomModal from "./CustomModal";

import TopNavigation from "./TopNavigation";

export default function DepositWithdrawCoins(router) {
  const {
    history: {
      location: {
        state: {
          exchangeData: {
            exchangeIconUrl,
            exchangeUrl,
            userNameExchange,
            balance,
            id,
            userId,
            userIdExchange,
            exchangeName,
            isWithdraw,
          },
        },
      },
    },
  } = router;
  const [coins, setCoins] = useState(1000);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const handleSubmit = async () => {
    if (!coins)
      return toast("Please fill all the required fields", {
        type: "error",
      });
    handleRequest();
    const { data, statusCode, response, message } = await manageCoinsAPI({
      userExchangeIdId: id,
      creditType: isWithdraw ? "WITHDRAW" : "DEPOSIT",
      credit: coins,
      services: exchangeName,
    });
    if (statusCode != 200) {
      toast(message, {
        type: "error",
      });
    } else {
      toast(message);
    }
  };
  const handleRequest = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsModalOpen(true);
    }, 3000);
  };
  return (
    <div className="w-[100%]">
      <CustomModal isOpen={isModalOpen} onClose={setIsModalOpen}>
        <div className="w-[20vw] text-white flex flex-col items-center text-center">
          <p className="text-xl">Request Successfull</p>
          <p className="text-sm mt-5">
            {isWithdraw ? "Withdraw" : "Deposit"} Request submitted Successfully
            <br />
            You will be notified soon.
          </p>
          <button
            className="w-[30%] yellow_button font-bold p-3 mt-5 text-black flex justify-center items-center"
            onClick={() => history.push("/")}
          >
            Ok
          </button>
        </div>
      </CustomModal>
      <TopNavigation
        title={isWithdraw ? "Withdraw" : "Deposit"}
        isBackNav={true}
      />
      <div className="w-[100%] flex-col px-2 py-5 justify-center items-center flex ">
        <img
          className="mt-10 w-[80px] h-[80px] rounded-full"
          src={exchangeIconUrl}
        />
        <h2 className="text-3xl mt-2 text-gray-200">{exchangeName}</h2>
        <p className="mt-2 text-gray-300 text-xl">{exchangeUrl}</p>
        <p className="mt-2 text-gray-300 text-xl">{userNameExchange}</p>
        <div className="w-[100%] px-20 mt-5 flex flex-col">
          <span className="text-gray-300 text-sm mb-1">Coins*</span>
          <input
            onChange={(e) => setCoins(e.target.value)}
            value={coins}
            className="p-2 text-white outline-none rounded-md bg-[#1A1D20]"
            placeholder="Preferred username"
          />
        </div>
        <button
          onClick={handleSubmit}
          className="w-[70%] yellow_button font-bold p-3 mt-5 text-black flex justify-center items-center"
        >
          {isWithdraw ? `Continue to Withdraw` : `Continue to Pay ${coins}`}
        </button>
      </div>
    </div>
  );
}
