import React, { useContext, useState } from "react";
import OtpModal from "../components/OtpModal";
import TopNavigation from "../components/TopNavigation";
import { UserContext } from "../context/UserContext";
import { getOtpAPI, registerAPI } from "../service/AuthService";
import { toast } from "react-toastify";
import PhoneInput from "../components/PhoneInput";
import JSEncrypt from "jsencrypt";
import { PUBLIC_KEY } from "../utils/constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default function Register({ history }) {
  const {
    location: {
      state: { phoneNumber },
    },
  } = history;
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [nonVerifiedUser, setNonVerifiedUser] = useState({});
  const { isLoggedIn, setUser, user } = useContext(UserContext);
  const [validation, setValidation] = useState({
    isValid: false,
    message: "",
  });
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      toast("Password and Confirm Password do not match", { type: "error" });
      return;
    }
    if (!phoneNumber) {
      toast("Phone Number is required", { type: "error" });
      return;
    }
    if (!name) {
      toast("Name is required", { type: "error" });
      return;
    }
    if (!password) {
      toast("Password is required", { type: "error" });
      return;
    }
    if (password < 8) {
      toast("Password must be 8 characters long", { type: "error" });
      return;
    }
    if (!password.match(/[a-z]/g) || !password.match(/[A-Z]/g)) {
      toast("Password must contain uppercase and lowercase letters", {
        type: "error",
      });
      return;
    }

    try {
      setIsOtpModalOpen(true);
      const { data, statusCode, message, response } = await getOtpAPI(
        phoneNumber,
        "REGISTRATION"
      );
      if (statusCode == 200) {
        toast("OTP sent successfully", { type: "success" });
        return;
      } else if (!data) {
        const {
          data: { error },
        } = response.data;
        toast(error, { type: "error" });
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
    }
  };
  const registerUser = async (otp) => {
    const { data, statusCode, response, message } = await registerAPI({
      mobile: phoneNumber,
      password: encrypt.encrypt(password),
      name,
      refCode: referralCode,
      otp,
    });
    if (statusCode === 200) {
      setUser({ ...data, isLoggedIn: true });
      toast("User registered successfully", { type: "success" });
      history.push("/home");
    } else {
      toast(message, { type: "error" });
    }
  };
  return (
    <div className="component_body justify-start overflow-y-auto pb-5">
      <TopNavigation title={"REGISTER"} isBackNav={true} isLoginNav={true} />
      <OtpModal
        phoneNumber={phoneNumber}
        isOpen={isOtpModalOpen}
        onClose={setIsOtpModalOpen}
        payload={nonVerifiedUser}
        register={registerUser}
        isRegister={true}
      />
      <div className="w-[95%] mt-20 sm:w-[80%]  text-[#ffffff] px-5 py-5 sm:px-5 sm:py-5 rounded-[19px] flex flex-col items-start">
        <label className="text-sm mb-2">Full Name *</label>
        <div className="styled_input custom_input">
          <input
            className="border-none text-[#7C7C7C]"
            type="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <label className="mt-5 text-sm mb-2">Phone Number *</label>
        <div className="styled_input custom_input phone_input text-[#7C7C7C]">
         <PhoneInput
          selectedCountry={selectedCountry}
          handleSelectedCountry={setSelectedCountry}
          phoneNumber={phoneNumber}
          disabled={true}
          shadow={false}
          bg={"#1D2023"}
        />
        </div>
        <label className="mt-5 text-sm mb-2">Password *</label>
        <div className="styled_input custom_input">
          <input
            className="border-none text-[#7C7C7C]"
            onChange={(e) => setPassword(e.target.value)}
            type={isPasswordVisible ? "text" : "password"}
          />
          {isPasswordVisible ? (
            <svg
              onClick={() => setIsPasswordVisible(false)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          ) : (
            <svg
              onClick={() => setIsPasswordVisible(true)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              />
            </svg>
          )}
        </div>
        <label className="mt-5 text-sm mb-2">Confirm Password *</label>
        <div className="styled_input custom_input">
          <input
            className="border-none text-[#7C7C7C]"
            onChange={(e) => {
              if (e.target.value.length > 3 && e.target.value !== password) {
                setValidation({
                  isValid: false,
                  message: "Password and Confirm Password do not match",
                });
                setConfirmPassword(e.target.value);
              } else {
                setValidation({ isValid: true, message: "" });
                setConfirmPassword(e.target.value);
              }
            }}
            type={isConfirmPasswordVisible ? "text" : "password"}
          />
          {isConfirmPasswordVisible ? (
            <svg
              onClick={() => setIsConfirmPasswordVisible(false)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          ) : (
            <svg
              onClick={() => setIsConfirmPasswordVisible(true)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              />
            </svg>
          )}
        </div>
        {!validation.isValid ? (
          <span className="text-[#9d2e3d] text-sm mt-1">
            {validation.message}
          </span>
        ) : (
          <span className="text-[#9d2e3d] text-sm mt-2"></span>
        )}
        <label className="text-sm mt-2 mb-2">Referral/Promo Code </label>
        <div className="styled_input custom_input">
          <input onChange={(e) => setReferralCode(e.target.value)} className="text-[#7C7C7C]" />
        </div>
      </div>

        <div style={{marginTop:"20px"}}>
        <input type="checkbox" id="termsCheckbox" 
        style={{ 
          backgroundColor: '#FFBF441A',
          border:"1px solid white",
          padding:"10px", 
          color:"yellow", 
          borderRadius:"5px",
          }} />
        <label htmlFor="termsCheckbox" style={{ color: '#ffffff' , marginLeft:"13px"}}>I’ve read and accept the <Link to="terms-and-conditions" style={{ color: '#FFBF44',}} className="underline">Terms & Conditions</Link></label>
      </div>

      <button
        onClick={handleSubmit}
        // className="w-[50%] mt-5 py-2 blue_button  flex justify-center items-center"
        className="w-[50%] yellow_button p-3 mt-7 font-bold text-black flex justify-center items-center"
      >
        Submit
      </button>
    </div>
  );
}
