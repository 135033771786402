import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingContext } from '../context/LoadingContext';
import { UserContext } from '../context/UserContext';
import { getPaymentDetailsAPI, withdrawCoinsAPI } from '../service/UserService';
import TopNavigation from './TopNavigation';
import WalletCard from './UX/Wallet';
import WithdrawDepositCard from './WithdrawDepositCard';

export default function Withdraw() {
  const { setUser, user } = useContext(UserContext);
  const history = useHistory();
  const [paymentModes, setPaymentModes] = useState([]);
  const [coins, setCoins] = useState('');
  const [paymentType, setPaymentType] = useState(false);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState([]);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const getPaymentDetails = async () => {
    setIsLoading(true);
    const { data, statusCode, response } = await getPaymentDetailsAPI();
    if (statusCode == 200) {
      const {
        UserBankDetails,
        UserUPIDetails: { gpay, phonepe, paytm, bhim },
      } = data;
      if (
        UserBankDetails.length == 0 &&
        gpay.length == 0 &&
        phonepe.length == 0 &&
        paytm.length == 0 &&
        bhim.length == 0
      ) {
        setIsLoading(false);
        history.push('/home/addbankaccount');
        return;
      } else {
        let paymentModes_ = [];
        UserBankDetails.map((item) =>
          paymentModes_.push({
            ...item,
            type: item.bankType,
            name: item.bankName,
          })
        );
        gpay.map((item) =>
          paymentModes_.push({ ...item, type: 'UPI_GPAY', name: 'Google Pay' })
        );
        phonepe.map((item) =>
          paymentModes_.push({ ...item, type: 'UPI_PHONEPE', name: 'PhonePe' })
        );
        paytm.map((item) =>
          paymentModes_.push({ ...item, type: 'UPI_PAYTM', name: 'Paytm UPI' })
        );
        bhim.map((item) =>
          paymentModes_.push({ ...item, type: 'UPI_BHIM', name: 'BHIM UPI' })
        );
        setIsLoading(false);
        setPaymentModes(paymentModes_);
      }
    } else if (!data) {
      const {
        data: { error },
      } = response.data;
      setIsLoading(false);
      toast(error, { type: 'error' });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getPaymentDetails();
  }, []);
  const handleSubmit = async () => {
    if (!paymentType) {
      toast('Please select a payment type', { type: 'error' });
      return;
    }
    if (!parseInt(coins) || parseInt(coins) > 99999999 || parseInt(coins) < 0) {
      toast('Please enter valid amount', { type: 'error' });
      return;
    }
    if (parseInt(coins) > parseInt(user['Account Details'].balance)) {
      toast('Insufficient coins', { type: 'error' });
      return;
    }
    if (parseInt(coins) < 1000) {
      toast('Minimum withdraw amount is 1000', { type: 'error' });
      return;
    }
    if (selectedPaymentMode.length == 0) {
      toast('Please select a payment mode', { type: 'error' });
      return;
    }
    setIsLoading(true);
    const { statusCode, data, response } = await withdrawCoinsAPI({
      coins: parseInt(coins),
      paymentType,
    });
    if (statusCode == 200) {
      toast('Coins withdrawn request sent successfully', {
        type: 'success',
        duration: 5000,
      });
      setUser(data);
      setIsLoading(false);
      history.push('/home');
    } else if (!data) {
      setIsLoading(false);
      toast(response.data.message, { type: 'error' });
    }
  };
  return (
    <>
      <TopNavigation title={'Withdraw'} isBackNav={true} showNotification />
      <div className='container flex   flex-col items-center p-3 sm:p-0 sm:items-start'>
        <WithdrawDepositCard />
        <p className='text-[#F89B10] self-start m-5  text-center  text-lg'>
          Withdrawable Balance: ₹{user['Account Details'].balance}
        </p>
        <p className='text-gray-300 self-start mx-5'>Withdraw Coins*</p>
        <input
          onChange={(e) => setCoins(e.target.value)}
          className='bg-[#1D2023] text-white outline-none rounded-xl w-[100%] inner_shadow  p-4 mt-4'
          placeholder='Enter your amount'
        />
        <div className='bg-[#1D2023] rounded-[10px] w-[100%] p-5 mt-5'>
          <select
            onChange={(e) => setSelectedPaymentMode(e.target.value)}
            className='bg-[#1D2023]  text-white w-[100%] outline-none border-none'
          >
            <option value={null}>Select Withdraw Mode</option>
            {paymentModes.map((item, index) => (
              <option key={index} value={item.type}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        {selectedPaymentMode.length > 0 && (
          <div className='bg-[#1D2023] rounded-[10px] w-[100%] p-5 mt-5'>
            <select
              onChange={(e) => setPaymentType(e.target.value)}
              className='bg-[#1D2023]  text-white w-[100%] outline-none border-none'
            >
              <option value={null}>Select Account</option>
              {paymentModes
                .filter(({ type }) => type == selectedPaymentMode)
                .map(({ type, value, name, accountNumber }, index) => (
                  <option key={index} value={type}>
                    {value ? value : accountNumber}
                  </option>
                ))}
            </select>
          </div>
        )}

        <button
          className='w-[50%] primary-btn blue_button mt-5 self-center p-3 text-white'
          onClick={handleSubmit}
        >
          Withdraw
        </button>
      </div>
    </>
  );
}
