import React, { useContext, useEffect, useState } from "react";
import TopNavigation from "../../components/TopNavigation";
import { getPassbookAPI, getSignedUrlAPI } from "../../service/UserService";
import Table from "../../components/Table/Table";
import { UserContext } from "../../context/UserContext";
import { LoadingContext } from "../../context/LoadingContext";
export default function Passbook() {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageChangeEvent, setPageChangeEvent] = useState(0);
  const [tableData, setTableData] = useState({
    size: 0,
  });
  const getPassbook = async () => {
    setIsLoading(true);
    const {
      data: {
        ["User Transactions"]: { content, size, totalPages, numberOfElements },
      },
    } = await getPassbookAPI(pageLimit, currentPage);
    setTableData({ size, totalPages, numberOfElements });
    setIsLoading(false);
    setTransactions(content);
  };
  useEffect(() => {
    getPassbook();
  }, [currentPage, pageLimit, pageChangeEvent]);
  const nextPage = () => {
    if (currentPage < tableData.totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const [columns, setColumns] = useState([
    {
      Header: "Coins",
      accessor: "coins",
    },
    {
      Header: "Transaction Status",
      accessor: "transactionStatus",
      Cell: ({ row }) => {
        let color = "blue";
        if (row.original.transactionStatus === "APPROVED") {
          color = "green";
        } else if (row.original.transactionStatus === "REJECTED") {
          color = "red";
        }
        return (
          <div>
            <span
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${color}-100 text-${color}-800`}
            >
              {row.original.transactionStatus}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Transaction Type",
      accessor: "transactionType",
      Cell: ({ row }) => {
        let color = "blue";
        if (row.original.transactionType.includes("DEPOSIT")) {
          color = "green";
        } else if (
          row.original.transactionType.includes("WITHDRAW") ||
          row.original.transactionType.includes("CREATE")
        ) {
          color = "red";
        }
        return (
          <div>
            <span
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${color}-100 text-${color}-800`}
            >
              {row.original.transactionType.replaceAll("_", " ")}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Request Generated At",
      accessor: "requestGenerated",
      Cell: (row) => (
        <span className="text-gray-400 text-sm">
          {row.value ? new Date(row.value).toDateString() : "Not Validated"}
        </span>
      ),
    },
  ]);
  return (
    <>
      <TopNavigation title={"Passbook"} showNotification={true} />
      <div className="container overflow-y-hidden  pb-12 mt-5">
        <h1 className="border-[#FFBF44] text-[white] w-[12%] ml-2  mb-5 text-xl border-b-2 ">
          Pasbook
        </h1>
      {
        transactions.length>0?
       ( <Table
        size={tableData.size}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        data={transactions}
        columns={columns}
        totalPages={tableData.totalPages}
        nextPage={nextPage}
        prevPage={prevPage}
        showPagination={true}
      />
    ):(
      <div className="empty_page">
      <img src={require("../../assets/img/asset1.png")} />
      <p className="text-[#697483] mt-5 text-lg">
        You don’t have any transaction yet.
      </p>
    </div>
    )
      }
      </div>
    </>
  );
}
