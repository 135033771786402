import {
  AccordionHeader,
  Accordion,
  AccordionBody,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopNavigation from "../../components/TopNavigation";
import NoData from "../../components/UX/NoData";
import ProfileCard from "../../components/UX/ProfileCard";
import { getPaymentDetailsAPI } from "../../service/UserService";

export default function Withdrawal() {
  const [availableMethods, setAvailableMethods] = useState([
    {
      id: 1,
      name: "Bank Transfer",
      icon: (
        <img
          className="button_svg"
          src={require("../../assets/img/bank_icon.png")}
        />
      ),
      route: "/home/addbankaccount",
    },
    {
      id: 2,
      name: "Goolge Pay",
      icon: (
        <img
          className=" w-[40px] "
          src={require("../../assets/img/googlepay_icon.png")}
        />
      ),
      route: "/home/addbankaccount",
    },
    {
      id: 3,
      name: "PhonePe",
      icon: (
        <img
          className=" w-[40px] "
          src={require("../../assets/img/phonepe_icon.png")}
        />
      ),
      route: "/home/addbankaccount",
    },
    {
      id: 4,
      name: "Paytm",
      icon: (
        <img
          className=" w-[40px] "
          src={require("../../assets/img/paytm_icon.png")}
        />
      ),
      route: "/home/addbankaccount",
    },
  ]);
  const [open, setOpen] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    UserBankDetails: [],
    UserUPIDetails: {
      gpay: [],
      phonepe: [],
      paytm: [],
      bhim: [],
    },
  });
  const getPaymentMethods = async () => {
    const { data } = await getPaymentDetailsAPI();
    setPaymentDetails(data);
  };
  useEffect(() => {
    getPaymentMethods();
  }, [1]);
  const handleOpen = (value) => {
    setOpen(open === value ? null : value);
  };

  return (
    <>
      <TopNavigation isBackNav={true} title="Withdrawal" />
      <div className=" container p-5 sm:p-0">
        <ProfileCard showProfileAge={false} />
        {availableMethods.length == 0 ? (
          <NoData title={"Withdrawal"} />
        ) : (
          availableMethods.map(({ id, name, icon, route }, index) => (
            <Accordion open={index == open} onClick={() => handleOpen(index)}>
              <AccordionHeader className="bg-[#1D2023] border-none my-2 px-2">
                <div className="flex  w-[100%] flex-row  justify-between items-center cursor-pointer  mt-3 px-5 py-2 sm:px-10 sm:py-5 rounded-lg">
                  <div className="flex flex-row align-middle items-center">
                    {icon}
                    <p className="ml-5 text-white">{name}</p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="h-auto">
                <div className="w-[100%] h-[25vh] overflow-y-auto">
                  {id == 1 ? (
                    paymentDetails.UserBankDetails.length == 0 ? (
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-white text-center mt-5">
                          You have not added any bank account yet. Please add
                          your bank account to withdraw your funds.
                        </p>
                        <Link
                          to={route}
                          className="bg-[#1D2023] text-white px-5 py-2 rounded-lg mt-5"
                        >
                          Add Bank Account
                        </Link>
                      </div>
                    ) : (
                      <div className="flex flex-col h-[25vh] items-center justify-center ">
                        {paymentDetails.UserBankDetails.map(
                          ({ bankName, accountNumber, ifscCode }, index) => (
                            <div
                              className="flex flex-col bg-[#1D2023] px-2 py-2 items-center justify-center w-[100%] mb-1"
                              key={index}
                            >
                              <div className="flex flex-row items-center justify-between w-[100%]">
                                <p className="text-white">Bank Name</p>
                                <p className="text-white">{bankName}</p>
                              </div>
                              <div className="flex flex-row items-center justify-between w-[100%]">
                                <p className="text-white">Account Number</p>
                                <p className="text-white">{accountNumber}</p>
                              </div>
                              <div className="flex flex-row items-center justify-between w-[100%]">
                                <p className="text-white">IFSC</p>
                                <p className="text-white">{ifscCode}</p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )
                  ) : id == 2 ? (
                    paymentDetails.UserUPIDetails.gpay.length != 0 ? (
                      paymentDetails.UserUPIDetails.gpay.map(
                        ({ userName, value }, index) => (
                          <div
                            className="flex flex-col bg-[#1D2023] px-2 py-2 items-center justify-center w-[100%] mb-1"
                            key={index}
                          >
                            <div className="flex flex-row items-center justify-between w-[100%]">
                              <p className="text-white">Username</p>
                              <p className="text-white">{userName}</p>
                            </div>

                            <div className="flex flex-row items-center justify-between w-[100%]">
                              <p className="text-white">Phonenumber</p>
                              <p className="text-white">{value}</p>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-white text-center mt-5">
                          You have not added any Goolge Pay account yet. Please
                          add your Google Pay to withdraw your funds.
                        </p>
                        <Link
                          to={route}
                          className="bg-[#1D2023] text-white px-5 py-2 rounded-lg mt-5"
                        >
                          Add Google Pay account
                        </Link>
                      </div>
                    )
                  ) : id == 3 ? (
                    paymentDetails.UserUPIDetails.phonepe.length != 0 ? (
                      paymentDetails.UserUPIDetails.phonepe.map(
                        ({ userName, value }, index) => (
                          <div
                            className="flex flex-col bg-[#1D2023] px-2 py-2 items-center justify-center w-[100%] mb-1"
                            key={index}
                          >
                            <div className="flex flex-row items-center justify-between w-[100%]">
                              <p className="text-white">Username</p>
                              <p className="text-white">{userName}</p>
                            </div>
                            <div className="flex flex-row items-center justify-between w-[100%]">
                              <p className="text-white">Phonenumber</p>
                              <p className="text-white">{value}</p>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-white text-center mt-5">
                          You have not added any PhonePe account yet. Please add
                          your PhonePe to withdraw your funds.
                        </p>
                        <Link
                          to={route}
                          className="bg-[#1D2023] text-white px-5 py-2 rounded-lg mt-5"
                        >
                          Add PhonePe account
                        </Link>
                      </div>
                    )
                  ) : id == 4 ? (
                    paymentDetails.UserUPIDetails.paytm.length != 0 ? (
                      paymentDetails.UserUPIDetails.paytm.map(
                        ({ userName, value }, index) => (
                          <div
                            className="flex flex-col bg-[#1D2023] px-2 py-2 items-center justify-center w-[100%] mb-1"
                            key={index}
                          >
                            <div className="flex flex-row items-center justify-between w-[100%]">
                              <p className="text-white">Username</p>
                              <p className="text-white">{userName}</p>
                            </div>
                            <div className="flex flex-row items-center justify-between w-[100%]">
                              <p className="text-white">Phonenumber</p>
                              <p className="text-white">{value}</p>
                            </div>
                            <Link
                              to={route}
                              className="bg-[#1D2023] text-white px-5 py-2 rounded-lg mt-5"
                            >
                              Add New
                            </Link>
                          </div>
                        )
                      )
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-white text-center mt-5">
                          You have not added any Paytm account yet. Please add
                          your Paytm to withdraw your funds.
                        </p>
                        <Link
                          to={route}
                          className="bg-[#1D2023] text-white px-5 py-2 rounded-lg mt-5"
                        >
                          Add Paytm account
                        </Link>
                      </div>
                    )
                  ) : id == 5 ? (
                    paymentDetails.UserUPIDetails.bhim.length == 0 ? (
                      paymentDetails.UserUPIDetails.bhim.map(
                        ({ userName, value }, index) => (
                          <div
                            className="flex flex-col bg-[#1D2023] px-2 py-2 items-center justify-center w-[100%] mb-1"
                            key={index}
                          >
                            <div className="flex flex-row items-center justify-between w-[100%]">
                              <p className="text-white">Username</p>
                              <p className="text-white">{userName}</p>
                            </div>
                            <div className="flex flex-row items-center justify-between w-[100%]">
                              <p className="text-white">Phonenumber</p>
                              <p className="text-white">{value}</p>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-white text-center mt-5">
                          You have not added any BHIM account yet. Please add
                          your BHIM to withdraw your funds.
                        </p>
                        <Link
                          to={route}
                          className="bg-[#1D2023] text-white px-5 py-2 rounded-lg mt-5"
                        >
                          Add BHIM account
                        </Link>
                      </div>
                    )
                  ) : null}
                </div>
              </AccordionBody>
            </Accordion>
          ))
        )}
      </div>
    </>
  );
}
