import React, { useEffect, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { REACT_APP_API_URL } from '../utils/config';
import { countryFlags } from '../utils/constants';
export default function PhoneInput({
  handleInputChange,
  phoneNumber,
  selectedCountry,
  handleSelectedCountry,
  shadow,
  bg,
  disabled,
  setValidationError,
  validationError,
}) {
  const [validation, setValidation] = useState({
    isValid: false,
    message: '',
  });

  useEffect(() => {
    setValidation(validationError ? validationError : validation);
  }, [validationError]);

  const handleInputChange_ = (e) => {
    var phone = e.target.value;
    if (phone == '' || !phone) {
      setValidation({
        isValid: false,
        message: 'Phone number is required',
      });
      setValidationError(true);
      return;
    }
    if (phone.length >= 1 && phone.length != 10) {
      setValidation({
        isValid: false,
        message: 'Phone number must be 10 digits *',
      });
      setValidationError(true);
    } else if (phone == '' || !phone || phone.length == 0) {
      setValidation({
        isValid: false,
        message: 'Phone number is mandatory *',
      });
      setValidationError(true);
    } else {
      setValidation({
        isValid: true,
        message: '',
      });
      setValidationError(false);
    }
    handleInputChange(phone);
  };
  console.log(getCountries(), selectedCountry, 'getCountries');
  return (
    <>
      <div
        style={{
          backgroundColor: bg ? bg : '#1A1D20',
        }}
        className={`flex flex-row rounded-[10px] w-[100%] align-middle items-center p-[6px] ${
          shadow ? "inner_shadow" : ""
        }`}
      >
        <img
          className='w-[30px] h-[20px]  sm:w-[40px] sm:h-[30px]'
          src={countryFlags.find((j) => j.code == selectedCountry).image}
          alt='flag'
        />
        <select
          disabled={disabled}
          className='bg-transparent text-white outline-none  shadow-none border-none'
          onChange={(e) => handleSelectedCountry(e.target.value)}
        >
          {getCountries().map((country, index) => (
            <option
              selected={country == selectedCountry}
              className='text-black'
              key={index}
              value={country}
            >
              <span>{"+" + getCountryCallingCode(country)}</span> 
            </option>
          ))}
        </select>
        {/* <div class="border-l border-white h-full"></div> */}
        <div className="border-l border-gray-400 h-[30px] mx-2"></div>

        <input
          type='phone'
          disabled={disabled}
          className="w-[100%] text-[#7C7C7C] outline-none border-none bg-transparent pl-2"
          autoFocus
          onChange={handleInputChange_}
          value={phoneNumber}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </div>

      <div className='text-[#9d2e3d] h-[20px] text-sm mt-1'>
        {validation.message && validation.message}
      </div>
    </>
  );
}



























