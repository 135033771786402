import React from "react";

const NoData = ({ title, is404 }) => {
  return (
    <div className="empty_page">
      <img src={require("../../assets/img/asset1.png")} />
      {!is404 ? (
        <p className="text-[#697483] mt-5 text-lg">
          You don’t have any {title} yet.
        </p>
      ) : (
        <p className="text-[#697483] mt-5 text-center text-lg">
          Oops! Page not found.
        </p>
      )}
    </div>
  );
};

export default NoData;
