import { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  DiscountIcon,
  HomeIcon,
  PassBookIcon,
  ProfileIcon,
} from '../assets/svg/SvgComponents';
import { UserContext } from '../context/UserContext';
import { getProfileAPI } from '../service/UserService';

const BottomNavigationBar = () => {
  const { setUser, user } = useContext(UserContext);

  const tabs = [
    {
      route: '/home',
      label: 'Home',
      icon: <HomeIcon />,
      name: 'Home',
      exact: true, // Ensure exact match for the Home route
    },
    {
      route: '/home/offers',
      label: 'Offers',
      icon: <DiscountIcon />,
      name: 'Offers',
    },
    {
      route: '/home/passbook',
      label: 'Passbook',
      icon: <PassBookIcon />,
      name: 'Passbook',
    },
    {
      route: '/home/ids',
      label: 'Ids',
      icon: <ProfileIcon />,
      name: 'Ids',
    },
  ];

  const getProfileData = async () => {
    const { data } = await getProfileAPI();
    setUser({
      ...user,
      ...data,
    });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div className="nav_wrapper">
      <div className="navbar_bottom">
        {tabs.map(({ route, icon, name, exact }, index) => (
          <NavLink
            key={index}
            exact={exact} // Ensure exact matching for the Home route
            activeClassName="navbar_menu_item_selected"
            className="navbar_menu_item"
            to={route}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p>{icon}</p>
              <p style={{ fontSize: '12px' }}>{name}</p>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default BottomNavigationBar;
