import React from 'react';
import TopNavigation from '../../components/TopNavigation';

export default function HelpDesk() {
  return (
    <>
      <TopNavigation isBackNav={true} title='Helpdesk' />
      <div className='flex flex-col justify-center align-middle items-center p-5 sm:p-0'>
        <div className='bg-[#1D2023] mt-10 rounded-xl flex flex-row justify-between px-10 py-5 align-middle items-center'>
          <p className='text-2xl text-white'>
            How Can <br /> We <span className='text-[#FFBF44]'>Help you.</span>
          </p>
          <img
            className='w-[40%]'
            src={require('../../assets/img/asset2.png')}
          />
        </div>
        <div className='support_icon mt-8'>
          <img src={require('../../assets/img/whatsapp_icon.png')} />
          <p className='mx-4'>
            <a
              href="https://wa.me/+447537102026"
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: 'pointer' }}
            >
              Whatsapp Support
            </a>
          </p>

        </div>
        <div className='support_icon mt-8'>
          <img
            className='h-5 w-5'
            src={require('../../assets/img/call_icon.png')}
          />
          <p className='mx-4'>
            <a href="tel:+447537102026" style={{ cursor: 'pointer' }}>
              Call Us
            </a>
          </p>

        </div>
      </div>
    </>
  );
}
