import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Table from "../../components/Table/Table";
import TopNavigation from "../../components/TopNavigation";
import { LoadingContext } from "../../context/LoadingContext";
import {
  getPassbookAPI,
  getSignedUrlAPI,
  getUserIdDetailsAPI,
} from "../../service/UserService";

export default function IdDetails(router) {
  const {
    history: {
      location: {
        state: {
          exchangeData: {
            exchangeIconUrl,
            exchangeUrl,
            userNameExchange,
            id,
            userId,
            userIdExchange,
            exchangeName,
          },
        },
      },
    },
  } = router;
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [pageLimit, setPageLimit] = useState(10);
  const [tableData, setTableData] = useState({
    size: 0,
  });
  const nextPage = () => {
    if (currentPage < tableData.totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage >= 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const [password, setPassword] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [balance, setBalance] = useState(0);
  const getPassbook = async () => {
    setIsLoading(true);
    const { data } = await getUserIdDetailsAPI(pageLimit, currentPage, id);
    setPassword(data.password);
    setCreatedAt(data.createdAt);
    setBalance(data.balance);
    let transactions_ = data["User Transactions"].content;
    setTableData({
      size: data["User Transactions"].size,
      totalPages: data["User Transactions"].totalPages,
      numberOfelements: data["User Transactions"].numberOfElements,
    });
    setIsLoading(false);
    setTransactions(transactions_);
  };
  const [columns, setColumns] = useState([
    {
      Header: "Coins",
      accessor: "coins",
    },
    {
      Header: "Transaction Status",
      accessor: "transactionStatus",
      Cell: ({ row }) => {
        let color = "blue";
        if (row.original.transactionStatus === "APPROVED") {
          color = "green";
        } else if (row.original.transactionStatus === "REJECTED") {
          color = "red";
        }
        return (
          <div>
            <span
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${color}-100 text-${color}-800`}
            >
              {row.original.transactionStatus}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Transaction Type",
      accessor: "transactionType",
      Cell: ({ row }) => {
        let color = "blue";
        if (row.original.transactionType.includes("DEPOSIT")) {
          color = "green";
        } else if (
          row.original.transactionType.includes("WITHDRAW") ||
          row.original.transactionType.includes("CREATE")
        ) {
          color = "red";
        }
        return (
          <div>
            <span
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${color}-100 text-${color}-800`}
            >
              {row.original.transactionType.replaceAll("_", " ")}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Request Generated At",
      accessor: "requestGenerated",
      Cell: (row) => (
        <span className="text-gray-400 text-sm">
          {row.value ? new Date(row.value).toDateString() : "Not Validated"}
        </span>
      ),
    },
  ]);
  useEffect(() => {
    getPassbook();
  }, [currentPage, pageLimit]);
  const copyToClipboard = (url) => {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast("Copied to clipboard", { type: "success" });
  };

  return (
    <div className="w-[100%] flex flex-col items-center">
      <TopNavigation
        title="ID Details"
        isBackNav={true}
        showNotification={true}
      />
      <div className="flex row w-[100%] p-5">
        <img className="w-[20%] rounded-full mr-5" src={exchangeIconUrl} />
        <div className="flex text-gray-300 flex-col w-[70%]">
          <a href={exchangeUrl}>
            <span className="flex flex-row text-lg">
              {exchangeUrl}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 ml-1 mt-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </span>
          </a>
          <span className="flex flex-row text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>
            {exchangeName}
          </span>
          <span className="flex flex-row text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
            Username : {userNameExchange}{" "}
            <img
              onClick={() => copyToClipboard(userNameExchange)}
              className="h-[20px] w-[15px] ml-2 cursor-pointer"
              src={require("../../assets/img/copy_icon.png")}
            />
          </span>
          <span className="flex flex-row text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
              />
            </svg>
            Password : {password}
            <img
              onClick={() => copyToClipboard(password)}
              className="h-[20px] w-[15px] ml-2 cursor-pointer"
              src={require("../../assets/img/copy_icon.png")}
            />
          </span>
          <span className="flex flex-row text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>
            {new Date(createdAt).toDateString()}
          </span>
        </div>
      </div>
      <div className="w-[95%] text-gray-300 p-2 rounded-lg bg-[#1B1E21] ">
        <div className="text-md font-bold border-b-[1px] w-[100%] mb-2 border-gray-600 ">
          ID Balance Details
        </div>
        <div className="flex px-2 flex-row w-[100%] justify-between">
          <span>Available balance</span>
          {balance}
        </div>
      </div>
      <div className="w-[100%] h-[60vh] px-5 mt-5 text-lg ">
        <span className="text-gray-300 border-b-[2px] mb-5 border-blue-500">
          Transaction History
        </span>
        <Table
          columns={columns}
          data={transactions}
          nextPage={nextPage}
          prevPage={prevPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          tableData={tableData}
          setTableData={setTableData}
          showPagination={true}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      </div>
    </div>
  );
}
