import JSEncrypt from 'jsencrypt';
import React from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import OtpModal from '../../components/OtpModal';
import TopNavigation from '../../components/TopNavigation';
import { LoadingContext } from '../../context/LoadingContext';
import { forgetPasswordAPI, getOtpAPI } from '../../service/AuthService';
import { changeAccountPasswordAPI } from '../../service/UserService';
import { PUBLIC_KEY } from '../../utils/constants';

export default function ChangePassword(router) {
  const { toChangeMainAccountPassword, mobileNumber } = router.location.state;
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const history = useHistory();
  const [isOldPasswordVisible, setIsOldPasswordVisible] = React.useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = React.useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    React.useState(false);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [isOtpModalOpen, setIsOtpModalOpen] = React.useState(false);
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  const handleSubmit = async () => {
    if (!oldPassword && !toChangeMainAccountPassword) {
      toast('Please enter your old password', { type: 'error' });
      return;
    }
    if (newPassword != confirmPassword) {
      toast('Password and Confirm Password should be same', {
        type: 'error',
      });
      return;
    }
    if (!newPassword || !confirmPassword) {
      toast('Please fill all the required fields', { type: 'error' });
      return;
    }
    if (newPassword.length < 8) {
      toast('Password must be 8 characters long', { type: 'error' });
      return;
    }
    if (!newPassword.match(/[a-z]/g) || !newPassword.match(/[A-Z]/g)) {
      toast('Password must contain uppercase and lowercase letters', {
        type: 'error',
      });
      return;
    }
    if (toChangeMainAccountPassword) {
      let otpType = 'CHANGE_PASSWORD';
      const { data, statusCode } = await getOtpAPI(mobileNumber, otpType);
      if (statusCode == 200) {
        setIsOtpModalOpen(true);
        toast('OTP sent successfully', { type: 'success' });
      } else {
        toast('Something went wrong', { type: 'error' });
      }
    } else {
      const { data, statusCode } = await changeAccountPasswordAPI({
        oldPassword: encrypt.encrypt(oldPassword),
        newPassword: encrypt.encrypt(newPassword),
      });
      if (statusCode == 500) {
        toast(data.error, { type: 'error' });
      }
      if (statusCode == 200) {
        toast('Password Changed Successfully', { type: 'success' });
        history.push('/home');
      }
    }
  };
  const forgetPassword = async (otp) => {
    const { data, statusCode } = await forgetPasswordAPI({
      mobileNumber,
      newPassword: encrypt.encrypt(newPassword),
      otp,
    });
    if (statusCode == 500) {
      toast(data.error, { type: 'error' });
    }
    if (statusCode == 200) {
      toast('Password Changed Successfully', { type: 'success' });
      history.push('/');
    }
  };

  return (
    <>
      <TopNavigation isBackNav={true} title={'Change Password'} />
      <OtpModal
        isOpen={isOtpModalOpen}
        onClose={setIsOtpModalOpen}
        forgotPassword={forgetPassword}
        isForgotPassword={true}
        phoneNumber={mobileNumber}
      />
      <div className='container p-5 sm:p-0'>
        {toChangeMainAccountPassword ? null : (
          <div className='bg-[#1D2023] inner_shadow rounded-[19px] p-5 mt-8 text-[#7C7C7C] '>
            <p className='mt-1'>Old Password</p>
            <div className='flex border-b-2 border-b-[#3D434A]'>
              <input
                onChange={(e) => setOldPassword(e.target.value)}
                type={isOldPasswordVisible ? 'text' : 'password'}
                className='bg-[#1D2023] outline-none  border-none text-white w-[100%] p-2 '
              />
              {isOldPasswordVisible ? (
                <div
                  onClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                    />
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
                    />
                  </svg>
                </div>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                  onClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                  />
                </svg>
              )}
            </div>
          </div>
        )}
        <div className='bg-[#1D2023] inner_shadow rounded-[19px] p-5 mt-8 text-[#7C7C7C] '>
          <p className='mt-1'>New Password</p>
          <div className='flex border-b-2 border-b-[#3D434A]'>
            <input
              onChange={(e) => setNewPassword(e.target.value)}
              type={isNewPasswordVisible ? 'text' : 'password'}
              className='bg-[#1D2023] outline-none  border-none text-white w-[100%] p-2 '
            />
            {isNewPasswordVisible ? (
              <div
                onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
                  />
                </svg>
              </div>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-6 h-6'
                onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                />
              </svg>
            )}
          </div>
          <p className='mt-5'>Confirm Password</p>
          <div className='flex border-b-2 border-b-[#3D434A]'>
            <input
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              className='bg-[#1D2023] outline-none border-none mb-2 text-white w-[100%] p-2  '
            />
            {isConfirmPasswordVisible ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth={2}
                onClick={() =>
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                }
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                />
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth={2}
                onClick={() =>
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                }
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21'
                />
              </svg>
            )}
          </div>
        </div>
        <div className='flex w-[100%] justify-center'>
          <button
            onClick={handleSubmit}
            className='mt-10 primary-btn blue_button  self-center px-10 py-3 text-gray-300'
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
