import axios from "axios";
import { REACT_APP_API_URL } from "../utils/config";

export const checkPhoneNumberAPI = async (mobile) => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}auth/check-mobile`, {
      params: {
        mobile,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const authenticateAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}auth/login`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const registerAPI = async (payload) => {
  try {
    const { data } = await axios.post(`${REACT_APP_API_URL}auth/user`, payload);
    return data;
  } catch (error) {
    return error;
  }
};

export const verifyOtpAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}auth/user/otp`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getOtpAPI = async (mobileNumber, otpType) => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}auth/otp`, {
      params: {
        mobileNumber,
        otpType:  "REGISTRATION",
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};


export const forgetPasswordAPI = async (payload) => { 
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}auth/user/forget-password`,
      {...payload}
    );
    return data;
  } catch (error) {
    return error;
  }
}