import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";

export default function ProfileCard({ showProfileAge }) {
  const { user } = useContext(UserContext);
  return (
    <div className="profile_card relative inner_shadow flex flex-row items-center rounded-xl justify-start mt-5 px-5  py-5 mb-10 sm:px-20">
      <img
        className="w-[80px]"
        src={require("../../assets/img/avatar_icon.png")}
      />
      <div className="flex flex-col text-white text-sm ml-5 sm:ml-5 ">
        <span className="text-xl">{user.User.name}</span>
        <p className="text-[#10A2F8]">+91-{user.User.mobileNumber}</p>
      </div>
      {showProfileAge && (
        <p className="absolute text-[#FEFEFE66] bottom-0 left-[35%] right-0 text-xs sm:left-[75%]   sm:bottom-5 sm:text-sm  ">
          {new Date(user.User.createdAt).toLocaleString()}
        </p>
      )}
    </div>
  );
}
