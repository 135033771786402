import React from "react";

export default function Wrapper(props) {
  return (
    <div className="flex justify-center bg-white">
      <div className="relative  bg-gradient-to-b from-[#212528] via-[#2E3339] to-[#212528]  sm:w-[50%] md:w-[40%] lg:w-[50%] w-[100vw] min-h-[100vh]  ">
        {props.children}
      </div>
    </div>
  );
}
