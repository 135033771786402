import React, { useState } from 'react';
import TopNavigation from '../../components/TopNavigation';

export default function ReferAndEarn() {
  const [referedFriends, setReferedFriends] = useState([
    {
      name: 'John Doe',
      email: '',
    },
    {
      name: 'Lorem Ipsum',
      email: '',
    },
    {
      name: 'ABC',
      email: '',
    },
  ]);
  return (
    <>
      <TopNavigation isBackNav={true} title={'Refer & Earn'} />
      <div className='refer_body relative'>
        <img
          className='w-[50%] sm:w-[22%] mt-10'
          src={require('../../assets/img/asset4.png')}
        />
        <div className='dashed_box mt-5 py-2 px-10'>
          <div className='border-r-2 pr-10 border-[lightgrey]'>
            <p>Your Referral Code</p>
            <h1>ABCDKIY639</h1>
          </div>
          <img
            className='ml-10'
            src={require('../../assets/img/copy_icon.png')}
          />
        </div>
        <p className='text-white my-5 text-lg'>Share your Referral code </p>
        <div className='flex flex-row w-[100%] bottom-[-30px] absolute align-middle justify-center'>
          <div className='social_button_w px-5 py-3 mr-10 '>
            <img
              className='mr-3'
              src={require('../../assets/img/whatsapp_icon.png')}
            />
            <p>Whatsapp</p>
          </div>
          <div className='social_button_t px-5 py-3 '>
            <img
              className='mr-3'
              src={require('../../assets/img/telegram_icon.png')}
            />
            <p>Telegram</p>
          </div>
        </div>
      </div>
      <div className='mt-10 w-[100%] px-10'>
        {referedFriends.map((item, index) => (
          <div className='flex  rounded-lg text-white justify-between text-center items-center px-10 py-2 w-[100%] flex-row mt-2 bg-[#1D2023]'>
            <div className='flex flex-row items-center'>
              <img
                className='h-[40px] w-[40px] mr-5'
                src={require('../../assets/img/avatar_icon.png')}
              />
              <p>Brock Lee</p>
            </div>
            <img
              className='w-[25px]'
              src={require('../../assets/img/share.png')}
            />
          </div>
        ))}
      </div>
    </>
  );
}
