import React from 'react';
import TopNavigation from '../../components/TopNavigation';

export default function TermsAndCondition() {
  return (
    <>
      <TopNavigation isBackNav={true} title={'Term & Conditions'} />
      <div className='container px-10 mt-10 overflow-y-auto pb-5'>
        <span className='text-white border-b-2 border-[#FFBF44]'>
          Terms and Conditions
        </span>
        <p className='mt-7 text-white'>
          Terms and Conditions agreements act as a legal contract between Wing
          Punt and the user who access your website and mobile app.
          <br /> You agree that by accessing the App, you have read, understood,
          and agree to be bound by all of these Terms and Conditions. If you do
          not agree with all of these Terms and Conditions, then you are
          expressly prohibited from using the App.
          <br /> You will be subject to, and will be deemed to have beenmade
          aware of and to have accepted, the changes in any revised Terms and
          Conditions by your continued use of the Site after the date such
          revised Terms and Conditions are posted.
          <br />
          <ul className='list-disc px-10 mt-5'>
            <li>
              Wing Punt is intended for users who are at least 18 years old.
              Person under the age of 18 is not permitted to register for the
              App.
            </li>
            <li>
              All the registration information you submit will be true,
              accurate, current, and complete.
            </li>
            <li>
              You will not use the Site for any illegal or unauthorized purpose.
            </li>
            <li>
              Your use of the Site will not violate any applicable law or
              regulation.
            </li>
          </ul>
          <br />
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site .
        </p>
      </div>
    </>
  );
}
