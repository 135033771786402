import React, { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import TopNavigation from '../../components/TopNavigation';
import { LoadingContext } from '../../context/LoadingContext';
import { postPaymentDetailsAPI } from '../../service/UserService';

export default function AddBank() {
  const [bankDetails, setBankDetails] = useState({});
  const history = useHistory();
  const [paymentType, setPaymentType] = useState('');
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const handleChange = (e) => {
    setBankDetails({
      ...bankDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    if (!paymentType) return;
    if (
      paymentType.startsWith('BANK') &&
      (!bankDetails.userName ||
        !bankDetails.accountNumber ||
        !bankDetails.ifscCode ||
        !bankDetails.bankName)
    ) {
      toast('Please fill all the details', { type: 'error' });
      return;
    } else if (
      paymentType.startsWith('UPI') &&
      (!bankDetails.userName || !bankDetails.value || !bankDetails.userName)
    ) {
      toast('Please fill all the details', { type: 'error' });
      return;
    }
    setIsLoading(true);
    const { data, statusCode, response, message } = await postPaymentDetailsAPI(
      {
        ...bankDetails,
        paymentType,
      }
    );
    if (statusCode == 200) {
      toast('Bank details added successfully', { type: 'success' });
      setIsLoading(false);
      history.push('/home');
    } else if (message) {
      toast(message, { type: 'error' });
      setIsLoading(false);
    } else if (!statusCode) {
      const {
        data: { error },
      } = response.data;
      setIsLoading(false);
      toast(error, { type: 'error' });
    }
    setIsLoading(false);
  };
  return (
    <>
      <TopNavigation
        backToHome={true}
        isBackNav={true}
        title='Add Bank Account'
      />
      <div className=' container overflow-y-auto p-5 flex flex-col overflow-hidden'>
        <span className=' flex mb-4'>
          <h1 className='text-white text-lg border-[#FFBF44] border-b-2'>
            Add Your Bank Account
          </h1>
        </span>
        <p className='text-gray-300'>
          Adding Bank Details is madatory for processing withdrawals.
        </p>
        <div className='bg-[#1D2023] rounded-[10px] p-5 mt-5'>
          <select
            onChange={(e) => setPaymentType(e.target.value)}
            className='bg-[#1D2023]  text-white w-[100%] border-none outline-none'
          >
            <option value={null}>Select Account Type to add</option>
            <option value={'BANK_SAVING'}>Savings Bank Account</option>
            <option value={'BANK_CURRENT'}>Current Bank Account</option>
            <option value={'UPI_PAYTM'}>Paytm UPI</option>
            <option value={'UPI_PHONEPE'}>Phonepe UPI</option>
            <option value={'UPI_GPAY'}>Google Pay</option>
            <option value={'UPI_BHIM'}>Bhim</option>
          </select>
        </div>
        {paymentType.startsWith('BANK') ? (
          <div>
            <div className='bg-[#1D2023] inner_shadow rounded-[19px] p-5 mt-8 text-[#7C7C7C] '>
              <p>Bank Account (Eg - HDFC BANK)</p>
              <input
                className='bg-[#1D2023] outline-none  text-white w-[100%] p-2 border-b-2 border-b-[#3D434A]'
                name='bankName'
                onChange={handleChange}
                value={bankDetails.bankName}
              />
              <p className='mt-5'>Account Number*</p>
              <input
                className='bg-[#1D2023] outline-none text-white w-[100%] p-2 border-b-2 border-b-[#3D434A]'
                name='accountNumber'
                onChange={handleChange}
                value={bankDetails.accountNumber}
              />
              <p className='mt-5'>IFSC Code*</p>
              <input
                className='bg-[#1D2023] outline-none text-white w-[100%] p-2 border-b-2 border-b-[#3D434A]'
                name='ifscCode'
                onChange={handleChange}
                value={bankDetails.ifscCode}
              />
              <p className='mt-5'>Account Holder Name*</p>
              <input
                className='bg-[#1D2023] outline-none mb-2 text-white w-[100%] p-2 border-b-2 border-b-[#3D434A]'
                name='userName'
                onChange={handleChange}
                value={bankDetails.accountHolderName}
              />
            </div>
          </div>
        ) : paymentType.startsWith('UPI') ? (
          <div className='bg-[#1D2023] inner_shadow rounded-[19px] p-5 mt-8 text-[#7C7C7C] '>
            <p>Full Name*</p>
            <input
              className='bg-[#1D2023] outline-none text-white w-[100%] p-2 border-b-2 border-b-[#3D434A]'
              name='userName'
              onChange={handleChange}
            />
            <p className='mt-5'>UPI ID*</p>
            <input
              className='bg-[#1D2023] outline-none text-white w-[100%] p-2 border-b-2 border-b-[#3D434A]'
              name='value'
              onChange={handleChange}
            />
            <p className='mt-5'>Phone Number*</p>
            <input
              className='bg-[#1D2023] outline-none mb-2 text-white w-[100%] p-2 border-b-2 border-b-[#3D434A]'
              name='value'
              onChange={handleChange}
            />
          </div>
        ) : null}


        {paymentType  
        &&
        <button
          onClick={handleSubmit}
          className='w-[40%] p-3 text-black font-bold mx-auto mt-5 yellow_button flex justify-center items-center '
        >
          Submit
        </button>
        }
      </div>
    </>
  );
}
