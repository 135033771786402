import React from "react";

export default function IconComponent({ size }) {
  return <div style={{ width: size }} className="ps_icon "></div>;
}

export const OtpIcon = ({ size }) => {
  return (
<svg width={size} height="71" viewBox="0 0 168 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_1997)">
<path d="M0 12.1108C0.657422 8.9142 1.89831 6.0192 4.3924 3.73938C7.45352 0.940877 11.1474 0 15.2234 0C53.7648 0.0120625 92.3102 0.00804168 130.852 0.00804168C138.231 0.00804168 145.611 0.00402084 152.994 0.00804168C156.401 0.00804168 159.606 0.683543 162.379 2.76634C165.999 5.48443 167.848 9.11122 167.947 13.5341C168.045 17.9732 167.992 22.4162 167.963 26.8552C167.951 28.7571 166.907 29.8547 165.28 29.8186C163.727 29.7824 162.745 28.7088 162.732 26.8874C162.704 22.77 162.728 18.6487 162.72 14.5273C162.708 8.60862 159.223 5.19895 153.134 5.15874C150.184 5.14265 147.23 5.15472 144.279 5.15472C101.362 5.15472 58.4489 5.1507 15.5316 5.1708C14.2291 5.1708 12.8855 5.21905 11.6323 5.52463C7.89728 6.43737 5.32101 9.62589 5.29224 13.5341C5.22239 22.7338 5.19774 31.9375 5.30046 41.1372C5.35388 45.8537 8.95326 49.2111 13.7853 49.396C14.4386 49.4202 15.096 49.4121 15.7535 49.4121C46.3688 49.4121 76.9841 49.4121 107.599 49.4202C108.413 49.4202 109.255 49.4443 110.028 49.6534C111.293 49.9911 111.91 50.9601 111.811 52.2026C111.708 53.437 110.977 54.2934 109.662 54.4824C108.964 54.5789 108.244 54.5588 107.534 54.5588C76.8649 54.5628 46.1921 54.4623 15.5234 54.6312C7.53159 54.6754 1.40113 50.1922 0.119158 42.9104C0.102722 42.8059 0.0451977 42.7094 0.00410889 42.6129C0 32.4442 0 22.2795 0 12.1108Z" fill="white"/>
<path d="M117.81 45.733C117.81 44.2373 117.777 42.7375 117.819 41.2418C117.909 37.9246 119.359 35.3673 122.433 33.8675C123.292 33.4494 123.402 32.9226 123.431 32.1185C123.526 29.3481 123.333 26.5054 123.908 23.8235C125.666 15.5687 133.395 10.3336 142.147 11.1498C149.95 11.8816 156.38 18.7693 156.746 26.8311C156.828 28.6445 156.697 30.4699 156.824 32.2793C156.861 32.8181 157.317 33.5419 157.798 33.8032C160.251 35.1462 162.112 37.1043 162.182 39.8063C162.305 44.5911 162.593 49.5207 161.64 54.1567C159.4 65.0572 149.231 71.8846 137.734 70.9075C127.109 70.0028 118.521 61.1489 117.88 50.3852C117.79 48.8412 117.868 47.2851 117.868 45.7371C117.843 45.733 117.827 45.733 117.81 45.733ZM140.134 38.1216C135.709 38.1216 131.283 38.1095 126.858 38.1256C124.2 38.1337 123.094 39.2233 123.07 41.8449C123.049 44.2494 123.041 46.6578 123.074 49.0623C123.111 51.9935 123.809 54.7397 125.288 57.3291C128.728 63.3483 135.265 66.5851 142.509 65.7327C148.898 64.9808 155.115 59.8703 156.401 53.5496C157.169 49.778 156.984 45.8054 157.104 41.9213C157.186 39.1952 156.031 38.1377 153.245 38.1296C148.873 38.1095 144.502 38.1216 140.134 38.1216ZM151.503 32.8824C151.503 30.9967 151.577 29.1913 151.491 27.394C151.228 22.0382 148.171 18.0938 143.244 16.6423C138.737 15.3154 133.782 16.972 130.893 20.7877C128.128 24.4427 128.687 28.6525 128.703 32.8824C136.337 32.8824 143.84 32.8824 151.503 32.8824Z" fill="white"/>
<path d="M36.1047 22.8585C38.0318 21.7728 39.663 20.828 41.3189 19.9192C43.0939 18.9462 44.6717 19.2035 45.4647 20.5666C46.2577 21.9256 45.6907 23.349 43.9116 24.3864C42.2803 25.3353 40.6327 26.2601 38.7837 27.3176C40.5916 28.3389 42.1858 29.2275 43.7719 30.1362C45.6537 31.2178 46.2619 32.6291 45.4812 34.0525C44.7046 35.4598 43.1021 35.6809 41.1997 34.6275C39.6013 33.7429 38.0071 32.8462 36.1088 31.7847C36.1088 33.9118 36.1211 35.7493 36.1047 37.5868C36.0883 39.6455 35.1596 40.7874 33.5161 40.7995C31.8643 40.8115 30.8823 39.6656 30.8576 37.6351C30.8371 35.7895 30.8535 33.9399 30.8535 31.8008C28.947 32.8663 27.3158 33.7911 25.6681 34.6999C23.8766 35.689 22.2947 35.4397 21.5264 34.0726C20.7498 32.6894 21.3127 31.254 23.0672 30.2327C24.7025 29.2798 26.3543 28.359 28.1869 27.3216C26.1982 26.1797 24.3615 25.1584 22.5536 24.0808C21.4647 23.4294 20.8237 22.3599 21.362 21.23C21.74 20.4379 22.6604 19.7182 23.511 19.3845C24.0615 19.1673 24.9819 19.5936 25.6311 19.9273C27.3117 20.7918 28.9306 21.7648 30.8576 22.8545C30.8576 20.6068 30.6933 18.5924 30.9316 16.6262C31.0425 15.7134 31.7411 14.6198 32.5176 14.1212C33.0764 13.7593 34.4159 13.9724 35.0158 14.4268C35.6281 14.8892 35.9979 15.9426 36.0595 16.7669C36.2033 18.6647 36.1047 20.5947 36.1047 22.8585Z" fill="#FFBF44"/>
<path d="M72.4602 27.3176C74.3092 28.371 76.0143 29.32 77.6908 30.3091C79.396 31.3183 79.8931 32.6653 79.1207 34.0404C78.34 35.4357 76.795 35.693 74.9912 34.7039C73.3436 33.7992 71.7123 32.8744 69.8099 31.8129C69.8099 34.0284 69.8551 35.9342 69.7976 37.8401C69.736 39.8867 68.5197 41.0246 66.7611 40.7512C65.286 40.522 64.5999 39.5731 64.5752 38.1779C64.5382 36.1634 64.567 34.145 64.567 31.7485C62.5988 32.8583 60.9676 33.8836 59.2295 34.7119C58.453 35.0818 57.3353 35.3914 56.6286 35.1059C55.8726 34.8044 54.9851 33.7911 54.9522 33.0553C54.9152 32.227 55.5562 31.0811 56.2753 30.5624C57.9352 29.3682 59.8171 28.4675 61.8716 27.2975C59.9281 26.1998 58.1407 25.2388 56.4026 24.1894C54.6153 23.1118 54.2989 21.2944 55.626 20.0278C56.6903 19.0105 57.8777 19.111 59.0857 19.7785C60.8238 20.7435 62.5577 21.7125 64.5588 22.8303C64.5588 20.6792 64.5423 18.8296 64.5629 16.98C64.5875 14.9334 65.5696 13.7472 67.1761 13.7633C68.7704 13.7794 69.777 14.9897 69.8099 17.0122C69.8387 18.8296 69.8181 20.647 69.8181 22.7982C71.2604 21.994 72.5012 21.3024 73.7462 20.6108C74.4119 20.2409 75.057 19.8268 75.7555 19.5332C77.1155 18.9623 78.5125 19.4086 79.1248 20.6148C79.7699 21.8854 79.5357 23.0716 78.3359 23.8717C76.9388 24.8005 75.435 25.5766 73.9804 26.4209C73.5161 26.6984 73.0559 26.9718 72.4602 27.3176Z" fill="#FFBF44"/>
<path d="M98.1573 31.7968C96.1645 32.9226 94.4511 33.9238 92.6966 34.8607C91.053 35.7412 89.4547 35.3191 88.7767 33.8997C88.1398 32.5768 88.5959 31.3103 90.1326 30.3895C91.8501 29.3642 93.6087 28.3992 95.5317 27.3095C93.6005 26.2119 91.7844 25.2388 90.034 24.1693C88.3617 23.152 88.0207 21.3306 89.2204 20.1042C90.2436 19.0588 91.4269 19.0708 92.6555 19.7463C94.4018 20.7113 96.1398 21.6884 98.1532 22.8102C98.1532 20.7636 98.1121 18.9703 98.1737 17.1851C98.1984 16.4573 98.2148 15.4843 98.6668 15.0701C99.3365 14.4509 100.388 13.9081 101.276 13.9121C102.529 13.9201 103.33 14.8932 103.363 16.1799C103.417 18.2948 103.38 20.4178 103.38 22.8585C105.274 21.801 106.856 20.8963 108.462 20.0358C109.029 19.7303 109.642 19.3804 110.262 19.3161C111.532 19.1834 112.473 19.7624 112.945 20.9687C113.459 22.2875 112.953 23.3128 111.836 24.0044C110.044 25.1182 108.187 26.1274 106.137 27.3095C108.027 28.3911 109.74 29.3722 111.45 30.3533C112.567 30.9967 113.327 31.8652 113.093 33.2202C112.768 35.0778 110.887 35.8739 109.017 34.9009C107.242 33.9761 105.516 32.9508 103.417 31.7727C103.417 33.4534 103.421 34.8567 103.417 36.2559C103.413 37.0038 103.441 37.7597 103.367 38.4995C103.232 39.8465 102.435 40.6668 101.054 40.7753C99.6118 40.8839 98.5271 40.2245 98.3422 38.7931C98.1367 37.2129 98.2025 35.5965 98.1614 33.9962C98.1408 33.3689 98.1573 32.7457 98.1573 31.7968Z" fill="#FFBF44"/>
<path d="M140.023 43.9518C142.164 43.9156 144.202 45.2304 145.064 47.2006C145.936 49.1829 145.484 51.6718 143.791 53.0751C142.998 53.7345 142.677 54.3577 142.685 55.3589C142.702 57.116 141.592 58.266 140.084 58.258C138.581 58.2499 137.471 57.104 137.492 55.3429C137.504 54.3336 137.167 53.7144 136.382 53.055C134.743 51.6799 134.275 49.2633 135.063 47.3213C135.869 45.347 137.866 43.988 140.023 43.9518Z" fill="#FFBF44"/>
</g>
<defs>
<clipPath id="clip0_1_1997">
<rect width="168" height="71" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};
