import { Redirect, Route, Switch } from "react-router-dom";
import GetStarted from "../pages/GetStarted";
import HomePage from "../pages/HomePages/HomePage";
import Offers from "../pages/HomePages/Offers";
import Login from "../pages/Login";
import AuthRoutes from "./AuthRoutes";
import UnAuthRoutes from "./UnAuthRoutes";
import Passbook from "../pages/HomePages/Passbook";
import Ids from "../pages/HomePages/Ids";
import Withdraw from "../components/Withdraw";
import Deposit from "../components/Deposit";
import Withdrawal from "../pages/HomePages/Withdrawal";
import Notification from "../pages/HomePages/Notification";
import AddBank from "../pages/HomePages/AddBank";
import { DrawerContext } from "../context/DrawerContext";
import SideBar from "../components/SideBar";
import { useContext } from "react";
import HelpDesk from "../pages/HomePages/HelpDesk";
import TermsAndCondition from "../pages/HomePages/TermsAndCondition";
import TermsAndCond from "../pages/HomePages/TermsAndCon";
import Profile from "../pages/HomePages/Profile";
import ChangePassword from "../pages/HomePages/ChangePassword";
import ReferAndEarn from "../pages/HomePages/ReferAndEarn";
import Register from "../pages/Register";
import CreateId from "../components/CreateId";
import IdDetails from "../pages/HomePages/IdDetails";
import DepositWithdrawCoins from "../components/DepositWithdrawCoins";
import ChangeAccountPassword from "../pages/ChangeAccountPassword";
import Loading from "../components/UX/Loaders/Loading";
import { LoadingContext } from "../context/LoadingContext";
import Transaction from "../pages/HomePages/Transaction";
import NotFoundPage from "../pages/HomePages/404";
const Routes = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useContext(DrawerContext);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  return (
    <>
      {isSideBarOpen ? <SideBar /> : null}
      {isLoading ? <Loading title={"Please Wait"} /> : null}
      <Switch>
        <UnAuthRoutes exact path="/" component={GetStarted} />
        <UnAuthRoutes exact path="/login" component={Login} />
        <UnAuthRoutes exact path="/register" component={Register} />
        <UnAuthRoutes exact path="/terms-and-conditions" component={TermsAndCond} />
        <UnAuthRoutes
          exact
          path="/forgot-password"
          component={ChangePassword}
        />
        <AuthRoutes exact path="/home" component={HomePage} />
        <AuthRoutes exact path="/" component={HomePage} />
        <AuthRoutes
          isNavbarHidden={true}
          exact
          path="/home/withdrawal"
          component={Withdrawal}
        />
        <AuthRoutes
          exact
          path="/home/transaction/:transactionId"
          component={Transaction}
        />
        <AuthRoutes
          exact
          isNavbarHidden={true}
          path="/home/helpdesk"
          component={HelpDesk}
        />
        <AuthRoutes
          exact
          isNavbarHidden={true}
          path="/home/account-deposit-withdraw"
          component={DepositWithdrawCoins}
        />
        <AuthRoutes
          exact
          isNavbarHidden={true}
          path="/home/create-account"
          component={CreateId}
        />
        <AuthRoutes
          exact
          isNavbarHidden={true}
          path="/home/terms-and-conditions"
          component={TermsAndCondition}
        />
        <AuthRoutes exact path="/home" component={HomePage} />
        <AuthRoutes
          exact
          path="/home/account/change-password"
          component={ChangePassword}
          isNavbarHidden={true}
        />
        <AuthRoutes
          exact
          path="/home/refer-and-earn"
          component={ReferAndEarn}
          isNavbarHidden={true}
        />
        <AuthRoutes exact path="/home/offers" component={Offers} />
        <AuthRoutes exact path="/home/passbook" component={Passbook} />
        <AuthRoutes exact path="/home/ids" component={Ids} />
        <AuthRoutes
          exact
          path="/home/ids/change-password"
          component={ChangeAccountPassword}
        />
        <AuthRoutes
          exact
          path="/home/ids/create"
          component={() => <Ids currentPage={1} />}
        />
        <AuthRoutes
          exact
          path="/home/ids/myids"
          component={() => <Ids currentPage={0} />}
        />
        <AuthRoutes
          exact
          path="/home/id/details"
          component={IdDetails}
          isNavbarHidden={true}
        />
        <AuthRoutes
          exact
          path="/home/withdraw"
          isNavbarHidden={true}
          component={Withdraw}
        />
        <AuthRoutes exact path="/home/deposit" component={Deposit} />
        <AuthRoutes
          isNavbarHidden={true}
          exact
          path="/home/profile"
          component={Profile}
        />
        <AuthRoutes
          isNavbarHidden={true}
          exact
          path="/home/withdrawal"
          component={Withdrawal}
        />
        <AuthRoutes
          exact
          path="/home/notifications"
          isNavbarHidden={true}
          component={Notification}
        />
        <AuthRoutes
          exact
          path="/home/addbankaccount"
          isNavbarHidden={true}
          component={AddBank}
        />
        <Route path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </>
  );
};

export default Routes;
