import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import { DrawerContext } from "./context/DrawerContext";
import { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext, UserProvider } from "./context/UserContext";
import { LoadingContext } from "./context/LoadingContext";
import { NotificationCountContext } from "./context/NotificationCountContext";
import { getNotificationCountAPI } from "./service/UserService";
import { logOut } from "./utils/constants";
export default function App() {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const getNotificationCount = async () => {
    let storage = localStorage.getItem("user");
    if (storage) {
      storage = JSON.parse(storage);
      if (storage.isLoggedIn) {
        setInterval(async () => {
          try {
            const { data } = await getNotificationCountAPI();
            setNotificationCount(data["noti-count"]);
          } catch (e) {
            logOut();
          }
        }, 10000);
      }
    }
  };
  useEffect(() => {
    getNotificationCount();
  }, [1]);
  return (
    <BrowserRouter basename="/">
      <ToastContainer />
      <UserProvider>
        <NotificationCountContext.Provider
          value={[notificationCount, setNotificationCount]}
        >
          <LoadingContext.Provider value={[isLoading, setIsLoading]}>
            <DrawerContext.Provider value={[isSideBarOpen, setIsSideBarOpen]}>
              <Wrapper>
                <Routes />
              </Wrapper>
            </DrawerContext.Provider>
          </LoadingContext.Provider>
        </NotificationCountContext.Provider>
      </UserProvider>
    </BrowserRouter>
  );
}
