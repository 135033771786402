import React, { useState } from "react";
import TopNavigation from "../../components/TopNavigation";

export default function Offers() {
  const [offers, setOffers] = useState([]);
  return (
    <>
      <TopNavigation title="Offers" showNotification={true}  />
      <div className="home_page_body container">
          <h1 >Top Offers</h1>
          <div className="border-[#FFBF00] border-b-2 w-[65px]"></div>
        {offers.length == 0 && (
          <div className="empty_page">
            <img src={require("../../assets/img/asset1.png")} />
            <p className="text-[#697483] mt-5 text-lg">
              You don’t have any offers yet.
            </p>
          </div>
        )}
      </div>
    </>
  );
}
