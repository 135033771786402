import React from "react";
import TopNavigation from "../../components/TopNavigation";
import NoData from "../../components/UX/NoData";

export default function NotFoundPage() {
  return (
    <>
      <TopNavigation isBackNav={true} />
      <div className="h-[30vh]">
        <NoData is404={true} />
      </div>
    </>
  );
}
