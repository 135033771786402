import axios from "axios";
import { REACT_APP_API_URL } from "../utils/config";

const getTokenFromLocalStorage = () => {
  let data = localStorage.getItem("user");
  if (data) {
    const { token } = JSON.parse(data);
    return token;
  } else {
    return "";
  }
};
export const getProfileAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}auth/user-info`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const getPaymentModesAPI = async (mobile) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/payment-details`,
      {
        params: {
          mobile,
        },
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const uploadFileAPI = async (file) => {
  var formData = new FormData();
  formData.append("file", file);
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}acc/upload-doc`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getPaymentDetailsAPI = async () => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}acc/payment-details`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const postPaymentDetailsAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}acc/payment-details`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const withdrawCoinsAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}acc/withdraw-coins `,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const depositCoinsAPI = async (coins, paymentProofs, paymentType) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}acc/deposit-coins`,
      {
        coins,
        paymentProofs,
        paymentType,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getSettingsAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}auth/settings`, {
      headers: {
        Authorization: `Bearer ${await getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllExchangesAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}admin/exchange`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const createAccountAPI = async (service, payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}acc/account?services=${service}`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllIdsAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}acc/account`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const manageCoinsAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}acc/account/banking`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getPassbookAPI = async (size, pageNumber) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}user/transactions?pageNumber=${pageNumber}&size=${size}`,
      {
        sort: "DESC",
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getNotificationsAPI = async () => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}user/all-notification`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getNotificationCountAPI = async () => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}user/unread-notification`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getBannerAPI = async () => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}auth/dashboard-banner`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const unreadNotificationAPI = async (id) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}user/notifications/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getSignedUrlAPI = async (key) => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}acc/signed-url`, {
      params: {
        fileKey: key,
      },
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserIdDetailsAPI = async (size, pageNumber, id) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}user/transactions/user-exchange/${id}`,
      {
        params: {
          size,
          pageNumber,
        },
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const changePasswordAPI = async (payload) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}auth/change-password`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const changeAccountPasswordAPI = async (payload) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}auth/user/change-password`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const changeAccountsPasswordAPI = async (id, password, service) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}acc/account/password?userExchangeIdId=${id}&password=${password}&services=${service}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const closeIdAPI = async (payload) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}acc/account/status`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getTransactionDetailsAPI = async (id) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}user/transactions/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const downloadAPI = async (id) => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}auth/app-build`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};