import React, { useCallback, useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import TopNavigation from '../../components/TopNavigation';
import NoData from '../../components/UX/NoData';
import { useHistory } from 'react-router-dom';
import {
  getNotificationsAPI,
  unreadNotificationAPI,
} from '../../service/UserService';
import { useContext } from 'react';
import { LoadingContext } from '../../context/LoadingContext';
export default function Notification() {
  const [past_notifications, setPastNotifications] = useState([]);
  const [today_notifications, setTodayNotifications] = useState([]);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [selectedFilter, setSelectedFilter] = useState({
    name: 'Deposit',
    event: 'get-notifications',
  });
  const history = useHistory();
  useEffect(() => {
    getNotifications();
  }, [0]);
  const getNotifications = async () => {
    setIsLoading(true);
    const {
      data: { past_notifications, today_notifications },
    } = await getNotificationsAPI();
    setPastNotifications(past_notifications);
    setTodayNotifications(today_notifications);
    setIsLoading(false);
  };
  const handleViewDetails = (notification) => {
    history.push(`/payout-screenshots`, {
      isRedirected: false,
      notification,
    });
  };
  const handleReadNotification = async (notificationId) => {
    const { statusCode } = await unreadNotificationAPI(notificationId);
  };

  return (
    <div className='w-[100%]  containerp'>
      <TopNavigation isBackNav={true} title='Notifications' />
      {today_notifications.length > 0 && (
        <label
          className='block uppercase tracking-wide text-gray-300 ml-2 text-sm font-bold mt-5'
          htmlFor='grid-first-name'
        >
          Today's Notifications
        </label>
      )}
      <div className=' container p-5 h-[auto]   overflow-auto'>
        {!today_notifications && <NoData title={'Notifications'} />}
        {today_notifications.map(
          (
            {
              approvedCoins,
              coins,
              createdAt,
              description,
              notificationType,
              title,
              transactionType,
              transactionId,
              notificationId,
            },
            index
          ) => (
            <div className=' cursor-pointer bg-[#212528] relative rounded-xl flex flex-col px-5 py-8 mt-5'>
              <Collapsible
                trigger={
                  <div
                    onClick={() => handleReadNotification(notificationId)}
                    className='   relative  justify-between flex flex-row items-center'
                  >
                    <div className='flex flex-row items-center'>
                      <img
                        className='button_svg'
                        src={
                          notificationType == 'DEPOSIT' ||
                          notificationType == 'WITHDRAW'
                            ? require('../../assets/img/bank_icon.png')
                            : require('../../assets/img/next-icon-yellow.png')
                        }
                      />
                      <p className='text-white ml-10  text-lg'>{title}</p>
                    </div>
                    <img
                      className='w-4'
                      src={require('../../assets/img/down_caret_icon.png')}
                    />
                  </div>
                }
              >
                <div className=' mt-5 rounded-lg  pl-5 pr-2 py-2 text-gray-200'>
                  <p>{description}</p>
                </div>
                <div className='bg-[#1b1e20] mt-5 rounded-lg  pl-5 pr-2 py-2 text-gray-400'>
                  <div className='flex flex-row justify-between items-center'>
                    <p>Details</p>
                    {transactionId && (
                      <span
                        onClick={() =>
                          history.push(`/home/transaction/${transactionId}`)
                        }
                        className='bg-gray-700 px-2 text-sm rounded-md py-1 text-gray-200'
                      >
                        View Details
                      </span>
                    )}
                  </div>
                  <div className='mt-5'>
                    {coins && (
                      <div className='flex flex-row justify-between items-center'>
                        <p className='text-gray-400'>Coins</p>
                        <p>{coins}</p>
                      </div>
                    )}
                    {!coins && (
                      <div className='flex flex-row justify-between items-center'>
                        <p className='text-gray-400'>Requested At</p>
                        <p>{new Date(createdAt).toString()}</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className='absolute rounded top-0 right-0 noti-date'>
                  <p className='text-white text-xs px-2 py-1'>
                    {new Date(createdAt).toDateString()}
                  </p>
                </div>
              </Collapsible>
            </div>
          )
        )}
      </div>
      <label
        className='block uppercase tracking-wide text-gray-300 ml-2 text-sm font-bold mt-5'
        htmlFor='grid-first-name'
      >
        Earlier Notifications
      </label>
      <div className=' container p-5 h-[auto]   overflow-auto'>
        {!past_notifications && <NoData title={'Notifications'} />}
        {past_notifications.map(
          (
            {
              approvedCoins,
              coins,
              createdAt,
              description,
              notificationType,
              title,
              transactionType,
              transactionId,
              notificationId,
            },
            index
          ) => (
            <div className=' cursor-pointer bg-[#212528] relative rounded-xl flex flex-col px-5 py-8 mt-5'>
              <Collapsible
                trigger={
                  <div className='   relative  justify-between flex flex-row items-center'>
                    <div className='flex flex-row items-center'>
                      <img
                        className='button_svg'
                        src={
                          notificationType == 'DEPOSIT' ||
                          notificationType == 'WITHDRAW'
                            ? require('../../assets/img/bank_icon.png')
                            : require('../../assets/img/next-icon-yellow.png')
                        }
                      />
                      <p className='text-white ml-10  text-lg'>{title}</p>
                    </div>
                    <img
                      className='w-4'
                      src={require('../../assets/img/down_caret_icon.png')}
                    />
                  </div>
                }
              >
                <div className=' mt-5 rounded-lg  pl-5 pr-2 py-2 text-gray-200'>
                  <p>{description}</p>
                </div>
                <div className='bg-[#1b1e20] mt-5 rounded-lg  pl-5 pr-2 py-2 text-gray-400'>
                  <div className='flex flex-row justify-between items-center'>
                    <p>Details</p>
                    {transactionId && (
                      <span
                        onClick={() =>
                          history.push(`/home/transaction/${transactionId}`)
                        }
                        className='bg-gray-700 px-2 text-sm rounded-md py-1 text-gray-200'
                      >
                        View Details
                      </span>
                    )}
                  </div>
                  <div className='mt-5'>
                    {coins && (
                      <div className='flex flex-row justify-between items-center'>
                        <p className='text-gray-400'>Coins</p>
                        <p>{coins}</p>
                      </div>
                    )}
                    {!coins && (
                      <div className='flex flex-row justify-between items-center'>
                        <p className='text-gray-400'>Requested At</p>
                        <p>{new Date(createdAt).toString()}</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className='absolute noti-date rounded top-0 right-0'>
                  <p className='text-black text-xs px-2 py-1'>
                    {new Date(createdAt).toDateString()}
                  </p>
                </div>
              </Collapsible>
            </div>
          )
        )}
      </div>
    </div>
  );
}
