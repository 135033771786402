import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getPaymentModesAPI, getSettingsAPI } from '../service/UserService';
import { intCheck } from '../utils/constants';
import PaymentPage from './PaymentPage';
import TopNavigation from './TopNavigation';
import WalletCard from './UX/Wallet';
import WithdrawDepositCard from './WithdrawDepositCard';

export default function Deposit() {
  const [paymentModes, setPaymentModes] = useState(null);
  const [amount, setAmount] = useState('');
  const [setting, setSettings] = useState({});
  const handleSubmit = async () => {
    if (!intCheck(amount) || amount == '' || amount == 0) {
      toast.error('Please enter valid amount');
      return;
    }
    if (amount < setting.minDepositToWallet) {
      toast('Please enter valid amount', { type: 'error' });
      return;
    } else if (parseInt(amount) > setting.minDepositToWallet) {
      const { data, statusCode, response } = await getPaymentModesAPI();
      if (statusCode == 200) {
        const { PaymentDetails } = data;
        setPaymentModes(PaymentDetails);
      } else if (!statusCode) {
        const {
          data: { error },
        } = response.data;
        toast(error, { type: 'error' });
      }
    }
  };
  const getSettings = async () => {
    const { data, statusCode, response } = await getSettingsAPI();
    if (statusCode == 200) {
      const { settings } = data;
      setSettings(settings);
    } else if (!statusCode) {
      const {
        data: { error },
      } = response.data;
      toast(error, { type: 'error' });
    }
  };
  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      <TopNavigation
        isBackNav={paymentModes}
        title={paymentModes ? 'Pay' : 'Deposit'}
        showNotification={true}
      />
      {paymentModes ? (
        <PaymentPage paymentModes={paymentModes} amount={amount} />
      ) : (
        <div className='container flex  flex-col items-center p-3 sm:p-0 sm:items-start'>
          <WithdrawDepositCard />
          <p className='text-gray-300 self-start m-5 sm:mt-10 sm:mb-2 sm:mx-1'>
            Deposit Coins*
          </p>
          <input
            onChange={(e) => setAmount(e.target.value)}
            className='bg-[#1D2023] rounded-xl outline-none text-white w-[95%] inner_shadow  p-4 mt-4'
            placeholder='Enter your amount'
          />
          <p className='text-sm mt-3 text-gray-400'>
            Minimum deposit amount is coins {setting.minDepositToWallet}
          </p>
          <button
            className='w-[50%] primary-btn blue_button mt-5 self-center p-3 text-white'
            onClick={handleSubmit}
          >
            Deposit
          </button>
        </div>
      )}
    </>
  );
}
