import React, { useEffect, useState } from "react";
function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {}
}

function getLocalStorage(key, initialValue) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
}
export const UserContext = React.createContext();
export function UserProvider({ children }) {
  const [user, setUser] = useState(() =>
    getLocalStorage("user", {
      token: "",
      User: {
        createdAt: "",
        name: "",
        refCode: "",
        uid: 0,
        isVerified: false,
        mobileNumber: "",
      },
      isLoggedIn: false,
    })
  );
  useEffect(() => {
    setLocalStorage("user", user);
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user: user,
        isLoggedIn: user.isLoggedIn,
        setUser: (data) => setUser({ ...user, ...data }),
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
