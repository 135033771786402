import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';

const WithdrawDepositCard = ({ balance }) => {
  const { user } = useContext(UserContext);
  return (
    <div className='withdraw-deposit-card'>
      <div className='content-box'>
        <h2>
          WING <span>PAY</span>
        </h2>
        <p className='balance-text'>Wallet Balance</p>
        <p className='balance'>
          ₹
          {balance
            ? balance
            : user['Account Details'] && user['Account Details'].balance}
        </p>
      </div>
    </div>
  );
};

export default WithdrawDepositCard;
