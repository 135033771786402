import { saveAs } from 'file-saver';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { depositCoinsAPI, uploadFileAPI } from '../service/UserService';
import QrIcon from '../assets/img/qr_icon.png';
import BankIcon from '../assets/img/bank_icon.png';
import CustomModal from './CustomModal';
import { useContext } from 'react';
import { LoadingContext } from '../context/LoadingContext';
export default function PaymentPage({ amount, paymentModes }) {
  const uploadFileRef = useRef(null);
  const history = useHistory();
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("")
  const [paymentMode, setPaymentMode] = useState({
    type: null,
  });
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenShot, setScreenShot] = useState(null);
  const uploadFile = async () => {
    if (!paymentMode.type) {
      toast('Please select payment mode', { type: 'error' });
      return;
    }
    if (!screenShot) {
      toast('Please upload a screenshot', { type: 'error' });
      return;
    }
    const file = screenShot[0];
    setIsLoading(true);
    const { statusCode, data, response } = await uploadFileAPI(file);
    if (statusCode == 200) {
      const uri = data['File Url'].CREATED;
      toast('File uploaded successfully', { type: 'success' });
      await depositCoins(uri);
      setIsLoading(false);
    } else if (!statusCode) {
      const {
        data: { error },
      } = response.data;
      setIsLoading(false);
      toast(error, { type: 'error' });
    }
  };
  const depositCoins = async (uri) => {
    const { statusCode, data, response } = await depositCoinsAPI(
      parseInt(amount),
      [uri],
      paymentMode.type == 'bank' ? 'BANK_SAVING' : 'UPI_BHIM'
    );
    if (statusCode == 200) {
      toast('Coins deposit request successfully', {
        type: 'success',
        duration: 3000,
      });
      setIsLoading(false);
      setIsModalOpen(true);
    } else if (!statusCode) {
      const {
        data: { error },
      } = response.data;
      setIsLoading(false);
      toast(error, { type: 'error' });
    }
  };
  const downloadFile = (url) => {
    try {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          saveAs(blob, 'screenshot.png');
        });
    } catch (e) {
      console.log('Cors');
    }
  };
  const copyToClipboard = (url) => {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast('Copied to clipboard', { type: 'success' });
  };
  return (
    <div className='container scrollbar-thin scrollbar-thumb-black scrollbar-thumb-rounded-full scrollbar-track-[#1A1D20] flex overflow-auto  flex-col items-center text-white p-3 sm:p-0 sm:items-start'>
      <CustomModal isOpen={isModalOpen} onClose={setIsModalOpen}>
        <div className='w-[20vw] flex flex-col items-center text-center'>
          <p className='text-xl'>Request Successfull</p>
          <p className='text-sm mt-5'>
            Deposit Request Submit successfully.It will be processed in 24-72hrs
          </p>
          <button
            className='blue_button py-2 px-4'
            onClick={() => history.push('/')}
          >
            Ok
          </button>
        </div>
      </CustomModal>
      <div className='w-[100%] p-[3rem] text-center'>
        <h1 className='text-white text-3xl'>Pay ₹{amount}/-</h1>
      </div>
      <div className='w-[100%] mb-5'>
        <p className='text-white'>Pay Manually</p>
        <div className='flex flex-row p-2 bg-[#1A1D20] w-[100%] rounded-md'>
  {paymentModes.map((mode, index) =>
    mode.isMethodActive && (
      <div
        key={index}
        onClick={() => {
          setPaymentMode(mode);
          setSelectedPaymentMode(mode.paymentDetailsId); // Set the selected payment mode
        }}
        className={`flex flex-col mr-2 cursor-pointer items-center px-10 py-2 sm:px-20 sm:py-5 rounded-lg ${
          selectedPaymentMode === mode.paymentDetailsId ? 'bg-[#2a2a2a]' : 'bg-[#232323]' // Highlight selected payment mode
        }`}
      >
        <img
          className='h-[40px] w-[40px]'
          src={mode.type === 'bank' ? BankIcon : QrIcon}
          alt='icon'
        />
        <p className='text-sm mt-2'>
          {mode?.type?.toLocaleUpperCase()}
        </p>
      </div>
    )
  )}
</div>

      </div>
      <div className='w-[100%] mb-10 sm:px-5'>
        {paymentMode.type == 'upi' ? (
          <div className='p-2  rounded-md bg-[#1A1D20]'>
            <p className='border-b-2 border-[grey]'>QR code for payment</p>
            <div className='flex flex-row  justify-center'>
              {paymentMode.qrCodeImageUrl && (
                <img
                  className='mt-4 w-[50%]'
                  src={paymentMode.qrCodeImageUrl}
                />
              )}
              <div className='flex w-[50%] flex-col ml-5 mt-2'>
                {paymentMode.upiId && (
                  <>
                    <label className='mb-2'>UPI ID</label>
                    <input
                      className='w-[100%] bg-[#0d0f11] p-2 rounded-md mb-2'
                      value={paymentMode.upiId}
                      disabled
                    />
                  </>
                )}
                <label className='mb-2'>Display Name</label>
                <input
                  className='w-[100%] bg-[#0d0f11] p-2 rounded-md'
                  value={paymentMode.accountHolderName}
                  disabled
                />
                {paymentMode.qrCodeImageUrl && (
                  <button
                    className='blue_button mt-5 p-3'
                    onClick={() => downloadFile(paymentMode.qrCodeImageUrl)}
                  >
                    Download QR
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : paymentMode.type == 'bank' ? (
          <div className='px-4 py-5  rounded-md bg-[#1A1D20]'>
            <div className='w-[100%] flex flex-row items-center justify-between'>
              <p>Bank Name</p>
              <p className='flex flexr-row items-center'>
                {paymentMode.bankName}
                <img
                  onClick={() => copyToClipboard(paymentMode.bankName)}
                  className='h-[20px] w-[15px] ml-2'
                  src={require('../assets/img/copy_icon.png')}
                />
              </p>
            </div>
            <div className='w-[100%] flex flex-row items-center justify-between'>
              <p>Account Number</p>
              <p className='flex flexr-row items-center'>
                {paymentMode.accountNumber}
                <img
                  onClick={() => copyToClipboard(paymentMode.accountNumber)}
                  className='h-[20px] w-[15px] ml-2'
                  src={require('../assets/img/copy_icon.png')}
                />
              </p>
            </div>
            <div className='w-[100%] flex flex-row items-center justify-between'>
              <p>IFSC Code</p>
              <p className='flex flexr-row items-center'>
                {paymentMode.ifscCode}
                <img
                  onClick={() => copyToClipboard(paymentMode.ifscCode)}
                  className='h-[20px] w-[15px] ml-2'
                  src={require('../assets/img/copy_icon.png')}
                />
              </p>
            </div>
            <div className='w-[100%] flex flex-row items-center justify-between'>
              <p>Account holder name</p>
              <p className='flex flexr-row items-center'>
                {paymentMode.accountHolderName}
                <img
                  onClick={() => copyToClipboard(paymentMode.accountHolderName)}
                  className='h-[20px] w-[15px] ml-2'
                  src={require('../assets/img/copy_icon.png')}
                />
              </p>
            </div>
          </div>
        ) : null}
        <input
          onChange={(e) => setScreenShot(e.target.files)}
          ref={uploadFileRef}
          className='hidden'
          type='file'
        />
        {screenShot ? (
          <img
            onClick={() => uploadFileRef.current.click()}
            className='max-h-[30vh] my-5 w-[100%]'
            src={URL.createObjectURL(screenShot[0])}
          />
        ) : (
          <div
            onClick={() => uploadFileRef.current.click()}
            className='cursor-pointer my-5  w-[100%] rounded-lg px-5 py-10 text-center border-4 border-dashed border-gray-500'
          >
            <p className='text-lg text-gray-500 font-bold'>
              UPLOAD PAYMENT SCREENSHOT *
            </p>
          </div>
        )}
        <div className='w-[100%] mb-5 flex flex-row items-center justify-center'>
          <button
            onClick={uploadFile}
            className='blue_button primary-btn py-2 px-5 self-center align-middle'
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
