import React, { useContext } from 'react';
import TopNavigation from '../../components/TopNavigation';
import ProfileCard from '../../components/UX/ProfileCard';
import { Link, useHistory } from 'react-router-dom';
import { logOut } from '../../utils/constants';
import { UserContext } from '../../context/UserContext';
export default function Profile() {
  const history = useHistory();
  const { user } = useContext(UserContext);
  return (
    <>
      <TopNavigation isBackNav={true} title='Profile' />
      <div className='flex flex-col w-[100%] px-5 justify-center align-middle'>
        <ProfileCard showProfileAge={true} />
        <div
          onClick={() =>
            history.push('/home/account/change-password', {
              mobileNumber: user.User.mobileNumber,
              toChangeMainAccountPassword: true,
            })
          }
          className='cursor-pointer w-[60%] sm:w-[45%] rounded-xl self-center  flex flex-row justify-between text-white bg-gradient-to-b from-[#0E71AC] to-[#032F48]'
        >
          <div className='w-[40px] sm:w-[20%] p-3  bg-gradient-to-b from-[#014973] to-[#032F48] flex items-center justify-center rounded-xl'>
            <img src={require('../../assets/img/change_password_icon.png')} />
          </div>
          <span className='w-[80%] flex justify-center items-center  text-center align-middle'>
            <p>Change Password</p>
          </span>
        </div>
        {/* <div className='flex flex-row justify-around  sm:justify-center mt-3'>
          <div className='bg-[#212528] sm:mr-5 inner_shadow px-3 py-3 sm:px-5 sm:py-4 rounded-xl flex flex-row items-center'>
            <img
              src={require('../../assets/img/trophy.png')}
              className='button_svg mt-0 mb-0'
            />
            <div className='ml-2 flex flex-col'>
              <p className='text-[#FFFFFF80] text-sm sm:text-lg'>
                Rewards Earned
              </p>
              <p className='text-white text-xs sm:text-sm  '>0 Coins</p>
            </div>
          </div>
          <div className='bg-[#212528] inner_shadow px-3 py-3 sm:px-5 sm:py-4 rounded-xl flex flex-row items-center'>
            <img
              src={require('../../assets/img/referred.png')}
              className='button_svg '
            />
            <div className='ml-2 flex flex-col'>
              <p className='text-[#FFFFFF80] text-sm sm:text-lg'>
                Users Referred
              </p>
              <p className='text-white text-xs sm:text-sm  '>0 Coins</p>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <img src={require('../../assets/img/referred-banner.png')} />
        </div> */}
        <div
          onClick={logOut}
          className='flex flex-row w-[100%] items-center justify-center mt-5'
        >
          {/* <div className='p-[10px] w-[180px] justify-between flex flex-row inner_shadow pr-10 bg-[#1B1E21] text-center justify-start text-white items-center  rounded-full'> */}
            {/* <div className='primary h-[40px] w-[40px] p-[5px] rounded-full'>
              <img
                className='h-[30px] w-[30px] mr-5'
                src={require('../../assets/img/logout-black.png')}
              />
            </div> */}

            <p className="w-[30%] yellow_button font-bold p-3 mt-5 text-black flex justify-center items-center">Logout</p>
            <p></p>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
