import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import BottomNavigationBar from "../components/BottomNavigation";
import { UserContext } from "../context/UserContext";

const AuthRoutes = ({ component: Component, isNavbarHidden, ...rest }) => {
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <div className="component_body">
            <Component {...props} />
            {!isNavbarHidden && <BottomNavigationBar />}
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthRoutes;
