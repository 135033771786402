import React from 'react';

export default function Loading({ title }) {
  return (
    <div className='h-[100vh] absolute z-[10000] flex flex-col items-center justify-center w-[100%] bg-[rgb(0,0,0,0.9)]'>
      <div role='status'>
        <img src={require('../../../assets/loading.gif')} />

        <span class='sr-only'>Loading...</span>
      </div>
      {title && (
        <span className='text-gray-300 self-center text-center text-lg'>
          {title}
        </span>
      )}
    </div>
  );
}
