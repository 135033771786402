import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import IconComponent from '../Icon';
import { useHistory } from 'react-router-dom';

export default function WalletCard({ balance }) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  return (
    <>
      <div className='flex flex-col items-center justify-center wallet-details'>
        <div className='flex items-center'>
          <div className='flex flex-col items-center justify-center deposite-col'>
            <h5>DEPOSIT</h5>
            <img
              onClick={() => history.push('/home/deposit') }
              src={require('../../assets/img/deposite.png')}
              alt='deposit'
            />
          </div>
          <div className='flex flex-col items-center justify-center logo-col'>
            <img src={require('../../assets/img/w-logo.png')} alt='logo' />
            <h5>Wallet Balance</h5>
            <p>
              <span>$ </span>
              {balance
                ? balance
                : user['Account Details'] && user['Account Details'].balance}
            </p>
          </div>
          <div className='flex flex-col items-center justify-center withdraw-col'>
            <h5>WITHDRAW</h5>
            <img
              src={require('../../assets/img/withdraw.png')}
              alt='withdraw'
              onClick={() => history.push('/home/withdraw')}
            />
          </div>
        </div>
      </div>
    </>
  );
}
