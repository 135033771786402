import React from "react";

const CustomModal = ({ title, children, onClose, isOpen, style }) =>
  isOpen ? (
    <div
      style={style}
      className="justify-center items-center bg-[#0000008d]   flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative  w-auto my-6  rounded-xl   mx-auto max-w-3xl">
        <div className="border-0   rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
          <div className="relative bg-[#212528] p-6 rounded-lg flex-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : null;

export default CustomModal;
