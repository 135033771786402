import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Popover,
  PopoverContent,
  PopoverHandler,
  Switch,
} from '@material-tailwind/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TopNavigation from '../../components/TopNavigation';
import NoData from '../../components/UX/NoData';
import {
  closeIdAPI,
  getAllExchangesAPI,
  getAllIdsAPI,
  getSignedUrlAPI,
} from '../../service/UserService';
import IconId from '../../assets/svg/icon-id.svg';
import IconPassword from '../../assets/svg/icon-password.svg';
import CustomModal from '../../components/CustomModal';
import { toast } from 'react-toastify';
import SmallCard from '../../components/UX/Loaders/Skeleton/SmallCard';
import { LoadingContext } from '../../context/LoadingContext';

console.log(LoadingContext);
export default function Ids({ currentPage }) {
  const [currentActivePage, setCurrentActivePage] = useState(
    currentPage ? currentPage : 0
  );
  const [myIds, setMyIds] = useState([]);
  const [isCloseIdModalOpen, setIsCloseIdModalOpen] = useState(false);
  const [activeBets, setActiveBets] = useState(false);
  const [reasonForClousre, setReasonForClousre] = useState('');
  const history = useHistory();
  const [createIdsList, setCreateIdsList] = useState([]);
  const [open, setOpen] = useState(null);
  const handleSearchInput = (e) => {
    createIdsList.map((item) => {
      if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        setMyIds([...myIds, item]);
      }
    });
  };
  const [isSkeletonLoaderVisible, setIsSkeletonLoaderVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useContext(LoadingContext);

  console.log(setIsLoading);

  const getAllExchanges = async () => {
    const { data } = await getAllExchangesAPI();
    let exchangeData = data['Exchange Data'];
    for (let index = 0; index < exchangeData.length; index++) {
      try {
        const {
          data: { url },
        } = await getSignedUrlAPI(exchangeData[index].exchangeIconUrl);
        exchangeData[index].exchangeIconUrl = url;
      } catch (e) {}
    }
    setCreateIdsList(exchangeData);
  };
  const getAllIds = async () => {
    // setIsLoading(true);
    const {
      data: { User_IDs },
    } = await getAllIdsAPI();
    let ids = User_IDs;
    for (let index = 0; index < ids.length; index++) {
      const {
        data: { url },
      } = await getSignedUrlAPI(ids[index].exchangeIconUrl);

      ids[index].exchangeIconUrl = url;
    }
    setIsLoading(false);
    setMyIds(ids);
  };
  useEffect(() => {
    getAllExchanges();
    getAllIds();
  }, []);

  const searchMyIds = (text) => {
    if (text == '') {
      getAllExchanges();
      getAllIds();
      return;
    }
    let filteredIds;
    if (currentActivePage == 0) {
      filteredIds = myIds.filter((item) =>
        currentActivePage == 0
          ? item.userNameExchange.toLowerCase().includes(text.toLowerCase())
          : item.exchangeName.toLowerCase().includes(text.toLowerCase())
      );
      setMyIds(filteredIds);
    } else {
      filteredIds = createIdsList.filter((item) =>
        currentActivePage == 0
          ? item.userNameExchange.toLowerCase().includes(text.toLowerCase())
          : item.exchangeName.toLowerCase().includes(text.toLowerCase())
      );
      setCreateIdsList(filteredIds);
    }
  };
  const handleOpen = (value) => {
    setOpen(open === value ? null : value);
  };
  const handleCloseId = (data) => {
    setIsCloseIdModalOpen(true);
    setSelectedId(data);
  };
  const closeId = async () => {
    if (reasonForClousre == '') {
      toast('Please enter reason for closure', {
        type: 'error',
      });
      return;
    }
    const { data, statusCode } = await closeIdAPI({
      userExchangeIdId: selectedId.id,
      reasonForClosure: reasonForClousre,
      activeBet: activeBets,
      service: selectedId.exchangeName,
    });
    if (statusCode == 200) {
      getAllIds();
      setIsCloseIdModalOpen(false);
      toast('Id closed successfully', {
        type: 'success',
      });
    } else {
      toast(data.message, {
        type: 'error',
      });
    }
  };

  const [fontSize, setFontSize] = useState(window.innerWidth <= 500 ? '9px' : 'inherit');

  useEffect(() => {
    const handleResize = () => {
      setFontSize(window.innerWidth <= 500 ? '9px' : 'inherit');
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <TopNavigation title={"ID's"} showNotification={true} />
      <div className='container h-auto px-[10px]'>
        <CustomModal
          isOpen={isCloseIdModalOpen}
          onClose={setIsCloseIdModalOpen}
        >
          <div className='flex flex-col text-white items-center justify-center'>
            <h1 className='text-xl font-bold'>Confirm Before You Proceed</h1>
            <span className='w-[100%] flex flex-row justify-between mt-5'>
              No active bets
              <Switch
                value={activeBets}
                onChange={(e) => setActiveBets(e.target.value)}
              />
            </span>

            <textarea
              onChange={(e) => setReasonForClousre(e.target.value)}
              className='mt-5 bg-[#1D2023]'
              placeholder='Reason For Closure'
            />
            <button onClick={closeId} className='blue_button mt-5 py-2 px-5'>
              Submit
            </button>
          </div>
        </CustomModal>
        <div className='ids-main flex mt-2 flex-row items-center bg-[#1D2023] rounded-full text-white inner_shadow'>
          <span
            onClick={() => setCurrentActivePage(0)}
            className={`rounded-full cursor-pointer ${
              currentActivePage == 0 ? 'selected' : ''
            } w-[50%] p-3 text-center`}
          >
            My ID's
          </span>
          <span
            onClick={() => setCurrentActivePage(1)}
            className={`rounded-full cursor-pointer ${
              currentActivePage == 1 ? 'selected' : ''
            } w-[50%] p-3 text-center`}
          >
            Create ID
          </span>
        </div>
        <div className='bg-[#212528] w-[100%] inner_shadow flex flex-row justify-between rounded-full px-5 py-3 mt-2 text-white items-center '>
          <img
            className='w-6 h-6 sm:w-6'
            src={require('../../assets/img/search_icon.png')}
          />
          <input
            placeholder='Search'
            onChange={(e) => searchMyIds(e.target.value)}
            className='w-[60%] sm:w-[80%] bg-[#212528] outline-none pl-2'
          />
          <div className='flex flex-row align-middle justify-center'>
            <p className='text-gray-300 text-sm sm:text-lg cursor-pointer'>
              All Sites
            </p>
          </div>
        </div>
        <div className='mt-5 overflow-y-auto pb-8'>
          {currentActivePage == 0 ? (
            myIds.length == 0 ? 
            (
              isSkeletonLoaderVisible ? 
              (
                <SmallCard />
              ) : (
                <NoData title="Id"  />
              )
            ) : (
              myIds.map(
                (
                  {
                    exchangeIconUrl,
                    exchangeUrl,
                    userNameExchange,
                    balance,
                    id,
                    userId,
                    userIdExchange,
                    exchangeName,
                  },
                  index
                ) => {
                  return (
                    <div className='flex self-center flex-row px-2 py-4  items-center justify-between align-middle mt-1 bg-[#212528]  w-[100%]'>
                      <div className='w-[80%] flex flex-row align-middle items-center'>
                      <div className='bg-black rounded-full w-14 h-14 mr-2 flex items-center justify-center'>
                        <img className='w-[80%]' src={exchangeIconUrl} />
                        </div>
                        <div className='flex flex-col'>
                          <a href={exchangeUrl}>
                            <span className='text-gray-300'>
                              {exchangeUrl.replaceAll('https://', '')}
                            </span>
                          </a>
                          <span className='text-[#FFFFFF61] text-sm'>
                            {userNameExchange}
                          </span>
                        </div>
                      </div>
                      <div className='flex flex-row'>
                        <span
                          onClick={() =>
                            history.push('/home/account-deposit-withdraw', {
                              exchangeData: {
                                exchangeIconUrl,
                                exchangeUrl,
                                userNameExchange,
                                balance,
                                id,
                                userId,
                                userIdExchange,
                                exchangeName,
                                isWithdraw: false,
                              },
                            })
                          }
                          className='bg-[#0E863E] cursor-pointer rounded-[50px]  p-1 text-center w-[30px] h-[30px] text-white'
                        >
                          D
                        </span>
                        <span
                          onClick={() =>
                            history.push('/home/account-deposit-withdraw', {
                              exchangeData: {
                                exchangeIconUrl,
                                exchangeUrl,
                                userNameExchange,
                                balance,
                                id,
                                userId,
                                userIdExchange,
                                exchangeName,
                                isWithdraw: true,
                              },
                            })
                          }
                          className='bg-[#DF4C44] cursor-pointer ml-1 rounded-[50px]  p-1 text-center w-[30px] h-[30px] text-white'
                        >
                          W
                        </span>
                        <span className=' cursor-pointer ml-1 rounded-[50px]  p-1 text-center w-[30px] h-[30px] text-white'>
                          <Popover
                            animate={{
                              mount: { scale: 1, y: 0 },
                              unmount: { scale: 0, y: 25 },
                            }}
                            offset={10}
                            placement='bottom-end'
                          >
                            <PopoverHandler>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                />
                              </svg>
                            </PopoverHandler>
                            <PopoverContent className='bg-[#2d3236] outline-none border-none'>
                              <div className='flex flex-col'>
                                <span
                                  onClick={() =>
                                    history.push(
                                      '/home/account-deposit-withdraw',
                                      {
                                        exchangeData: {
                                          exchangeIconUrl,
                                          exchangeUrl,
                                          userNameExchange,
                                          balance,
                                          id,
                                          userId,
                                          userIdExchange,
                                          exchangeName,
                                        },
                                      }
                                    )
                                  }
                                  className='text-gray-300 p-2 text-left cursor-pointer'
                                >
                                  Deposit
                                </span>
                                <span className='text-gray-300 p-2 text-left cursor-pointer'>
                                  Withdraw
                                </span>
                                <span
                                  onClick={() =>
                                    history.push('/home/id/details', {
                                      exchangeData: {
                                        exchangeIconUrl,
                                        exchangeUrl,
                                        userNameExchange,
                                        balance,
                                        id,
                                        userId,
                                        userIdExchange,
                                        exchangeName,
                                      },
                                    })
                                  }
                          ame='text-gray-300 p-2 text-left cursor-pointer'
                                >
                                  View Txns/Balance
                                </span>
                                <span
                                  onClick={() =>
                                    history.push('/home/ids/change-password', {
                                      exchangeData: {
                                        exchangeIconUrl,
                                        exchangeUrl,
                                        userNameExchange,
                                        balance,
                                        id,
                                        userId,
                                        userIdExchange,
                                        exchangeName,
                                      },
                                    })
                                  }
                                  className='text-gray-300 p-2 text-left cursor-pointer'
                                >
                                  Change Password
                                </span>
                                <span
                                  onClick={() =>
                                    handleCloseId({
                                      exchangeIconUrl,
                                      exchangeUrl,
                                      userNameExchange,
                                      balance,
                                      id,
                                      userId,
                                      userIdExchange,
                                      exchangeName,
                                    })
                                  }
                                  className='text-gray-300 p-2 text-left cursor-pointer'
                                >
                                  Close ID
                                </span>
                              </div>
                            </PopoverContent>
                          </Popover>
                        </span>
                      </div>
                    </div>
                  );
                }
              )
            )
          ) : createIdsList.length == 0 ? (
            isSkeletonLoaderVisible ? (
              <SmallCard />
            ) : (
              <NoData />
            )
          ) : (
            <div className='flex flex-col justify-center items-center'>
              {createIdsList.map(
                (
                  {
                    exchangeAccessToken,
                    exchangeAdminNameFromExchange,
                    exchangeAdminPassword,
                    exchangeAdminUrl,
                    exchangeAdminUserName,
                    exchangeIconUrl,
                    exchangeName,
                    exchangeUrl,
                    isSiteActive,
                  },
                  index
                ) => (
                  <Accordion
                    open={index == open}
                    onClick={() => handleOpen(index)}
                    className='flex flex-col px-4 py-4   mt-1 bg-[#212528] w-[100%]'
                  >
                    <AccordionHeader className='border-none p-0 flex-row'>
                      <div className='flex flex-row justify-between w-[100%] items-center'>
                        <div className='w-[100%] flex flex-row align-middle items-center'>
                        <div className='bg-black rounded-full w-14 h-14 mr-2 flex items-center justify-center'>
                        <img className='w-[80%]' src={exchangeIconUrl} />
                      </div>
                          <div className='flex flex-col text-left'>
                            <span className='text-white text-sm'>{exchangeName}</span>
                            <span className='text-[#FFFFFF61]' style={{ fontSize }}>
                              {exchangeUrl}
                            </span>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            history.push('/home/create-account', {
                              exchangeDetails: {
                                exchangeAccessToken,
                                exchangeAdminNameFromExchange,
                                exchangeAdminPassword,
                                exchangeAdminUrl,
                                exchangeAdminUserName,
                                exchangeIconUrl,
                                exchangeName,
                                exchangeUrl,
                                isSiteActive,
                              },
                            })
                          }
                          className='primary h-[38px] w-[100px] px-5 text-sm py-1 rounded-xl flex items-center jusitfy-center text-gray-200'
                        >
                          Create
                        </button>
                      </div>
                    </AccordionHeader>

                    <AccordionBody className=''>
                      <div className='w-[100%]  text-gray-300 p-2 rounded-lg bg-[#1B1E21] '>
                        <div className='text-md font-bold border-b-[1px] w-[100%] mb-2 border-gray-600 '>
                          ID Details
                        </div>
                        <div className='flex px-2 flex-row w-[100%] justify-between'>
                          <span className='text-[#FFFFFF61] flex row text-sm'>
                            <img className='h-[20px] mr-2' src={IconId} />
                          </span>
                          <span className='flex'>
                            Demo123{' '}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='gray'
                              className='w-4 h-4 ml-2 '
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75'
                              />
                            </svg>
                          </span>
                        </div>
                        <div className='flex px-2 mt-1 flex-row w-[100%] justify-between'>
                          <span className='text-[#FFFFFF61] flex row text-sm'>
                            <img className='h-[20px] mr-2' src={IconPassword} />
                          </span>
                          <span className='flex'>
                            1243434{' '}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='gray'
                              className='w-4 h-4 ml-2 '
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </AccordionBody>
                  </Accordion>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
